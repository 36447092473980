import { useEffect, useRef, RefObject } from 'react'

import styles from './Dropdown.module.scss'
import DropdownProps from './Dropdown.interface'

const Dropdown: React.FC<DropdownProps> = props => {
	const {
		children,
		styleRoot,
		styleWrapper,
		isOpen,
		onClose,
		component,
		className,
	} = props

	const dropdownRef: RefObject<HTMLDivElement> = useRef(null)

	useEffect(() => {
		function handleOutsideClick(event: MouseEvent) {
			if (
				isOpen &&
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				onClose()
			}
		}

		if (isOpen) {
			document.addEventListener('mouseup', handleOutsideClick)
		}

		return () => {
			document.removeEventListener('mouseup', handleOutsideClick)
		}
	}, [isOpen])

	const isDropdownOpen = isOpen ? styles.isOpen : ''

	return (
		<div
			className={[styles.root, className].join(' ').trim()}
			style={styleRoot}
		>
			{component}

			<div
				ref={dropdownRef}
				className={[styles.wrapper, isDropdownOpen].join(' ').trim()}
				style={styleWrapper}
			>
				<div className={styles.list}>{children}</div>
			</div>
		</div>
	)
}

export { Dropdown }
