import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { setOpenCase } from 'store/CaseSlice'

import { useSellItemMutation } from 'services/UserItemService'

import { Button } from 'components/ui'
import { Icon28ThunderWithArea } from 'assets/images/svg'

interface ItemButtonsProps {
	casePrice: number
	isSellButtonHidden?: boolean
}

const ItemButtons: React.FC<ItemButtonsProps> = props => {
	const dispatch = useAppDispatch()
	const openCase = useAppSelector(state => state.case.openCase)

	const [sellItem] = useSellItemMutation()

	const onSellItem = async () => {
		if (openCase?.userItem) {
			dispatch(setOpenCase(null))
			await sellItem(openCase?.userItem.id)
		}
	}

	return (
		<>
			{!props.isSellButtonHidden && (
				<Button
					onClick={onSellItem}
					text={`Продать за ${
						openCase?.item && openCase?.item.price
							? openCase?.item.price / 100
							: 0
					}`}
					after={<Icon28ThunderWithArea />}
					mode='secondary'
					maxWidth
					stretched
				/>
			)}
		</>
	)
}

export { ItemButtons }
