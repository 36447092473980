import { useEffect, useState } from 'react'
import { useFormatDate, useGoBack } from 'hooks'
import { useGetTransactionsQuery } from 'services/MoneyService'

import { Money } from 'models/Money'

import styles from './Profile.module.scss'

import { PayItem, PayItemSkeleton } from 'components/shared'
import { AsideButton, Section } from 'components/ui'

import { Icon28ArrowLeftOutline } from '@vkontakte/icons'

const ProfilePayHistory = () => {
	const goBack = useGoBack()
	const { formatDate } = useFormatDate()

	// dynamic pagination

	const [transactions, setTransactions] = useState<Money[]>([])
	const [currentPage, setCurrentPage] = useState(1)
	const [fetching, setFetching] = useState(true)

	const { data: transactionsData } = useGetTransactionsQuery({
		page: currentPage,
		limit: 20,
	})

	useEffect(() => {
		if (fetching && transactionsData && transactionsData.length > 0) {
			setTransactions([...transactions, ...transactionsData])
			setCurrentPage(prevState => prevState + 1)
			setFetching(false)
		}
	}, [fetching, transactionsData])

	useEffect(() => {
		document.addEventListener('scroll', scrollHandler)

		return function () {
			document.removeEventListener('scroll', scrollHandler)
		}
	}, [])

	const scrollHandler = (event: Event) => {
		if (
			event.target instanceof Document &&
			event.target.documentElement.scrollHeight -
				(event.target.documentElement.scrollTop + window.innerHeight) <
				100
		) {
			setFetching(true)
		}
	}

	// transaction sorting

	const transactionsByDate: Record<string, Money[]> = {}

	transactions?.forEach(transaction => {
		const date = formatDate(transaction.createdAt, false, true)

		if (!transactionsByDate[date]) {
			transactionsByDate[date] = []
		}

		transactionsByDate[date].push(transaction)
	})

	const uniqueDates = Object.keys(transactionsByDate)

	return (
		<Section countColumns={6}>
			<div className={styles['block-template']}>
				<div className={styles['heading-template']}>
					<AsideButton
						icon={<Icon28ArrowLeftOutline width={32} height={32} />}
						size='md'
						mode='secondary'
						appearance='gray'
						onClick={goBack}
					/>

					<h2>История пополнений</h2>
				</div>

				{transactions && transactions.length > 0 ? (
					uniqueDates.map(date => (
						<div key={date} className={styles['pay-history-list']}>
							<h4 className={styles['pay-date']}>{date}</h4>

							{transactionsByDate[date].map((item, index) => (
								<PayItem
									key={`${item.createdAt}-${item.id}-${index}`}
									amount={item.amount}
									description={
										item.description.includes(':')
											? item.description.split('п')[0]
											: item.description
									}
									additionalInfo={
										item.description.includes(':')
											? `п${item.description.split('п')[1]}`
											: `additional-info` +
											  `, ` +
											  formatDate(item.createdAt, true, false)
									}
								/>
							))}
						</div>
					))
				) : (
					<div className={styles['pay-history-list']}>
						<div className={styles['pay-history-list']}>
							{[...Array(3)].map((_, index) => (
								<PayItemSkeleton key={index} />
							))}
						</div>
					</div>
				)}

				{transactionsData && transactionsData.length >= 1 && fetching && (
					<div className={styles['pay-history-list']}>
						<h4 className={styles['pay-date']}>Сейчас загрузим...</h4>
						<PayItemSkeleton />
					</div>
				)}
			</div>
		</Section>
	)
}

export { ProfilePayHistory }
