import React from 'react'

import IconProps from './IconProps.interface'

const Icon28ThunderWithArea: React.FC<IconProps> = props => {
	let width = props.width ? props.width : 28
	let height = props.height ? props.height : 28
	let fill = props.fill ? props.fill : 'current'

	return (
		<svg
			style={{ width: width, height: height }}
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M14.7213 19.5376C16.8652 16.8261 19.0092 14.1147 21.1546 11.4047C21.2878 11.2368 21.4036 11.0515 21.2936 10.8372C21.1894 10.6172 20.9867 10.6172 20.7725 10.6172H16.0878C16.1805 10.455 16.2442 10.345 16.3079 10.2408C17.6015 8.06279 18.8952 5.88919 20.1888 3.71617C23.6713 5.81656 26 9.63593 26 13.9992C26 20.6266 20.6274 25.9992 14 25.9992C12.6416 25.9992 11.3359 25.7735 10.1184 25.3576C11.6531 23.4179 13.1872 21.4778 14.7213 19.5376Z'
				fill={fill}
			/>
			<path
				d='M8.56664 24.7016C4.66966 22.7192 2 18.671 2 13.9992C2 7.41792 7.29807 2.07405 13.8618 2C13.7311 2.06447 13.6346 2.17769 13.5515 2.35392C12.3403 4.94305 11.1258 7.52886 9.9105 10.1164C9.52215 10.9432 9.13371 11.7702 8.74528 12.5976L8.7441 12.6001C8.34494 13.4447 7.94578 14.2893 7.5524 15.1339C7.37868 15.5103 7.56398 15.7824 7.97512 15.7882H12.0749C12.0602 15.8306 12.0472 15.8689 12.0352 15.9042C12.0096 15.9798 11.9886 16.0417 11.9649 16.1009C11.2323 17.9511 10.5012 19.8027 9.77015 21.6543C9.36906 22.6701 8.96797 23.686 8.56664 24.7016Z'
				fill={fill}
			/>
		</svg>
	)
}

export { Icon28ThunderWithArea }
