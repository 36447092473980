import styles from './Avatar.module.scss'
import AvatarProps from './Avatar.interface'

import { Spinner } from 'components/ui'

const Avatar: React.FC<AvatarProps> = props => {
	const { src, alt, onClick, primary, width, height, style, name, lastName } =
		props

	const primaryMode = primary ? styles.primary : ''

	return (
		<div
			className={[styles.root, primaryMode, !src && styles.placeholder].join(
				' '
			)}
			style={{ width: width, height: height }}
		>
			<div
				onClick={onClick}
				className={[styles.wrapper].join(' ')}
				style={style}
			>
				{src ? (
					<img src={src} alt={alt} />
				) : name ? (
					<h3>
						{name && lastName
							? name.substring(0, 1) + lastName.substring(0, 1)
							: 'A'}
					</h3>
				) : (
					<Spinner width={28} height={28} />
				)}
			</div>
		</div>
	)
}

export { Avatar }
