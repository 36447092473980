import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useTelegram } from 'hooks/useTelegram'

import 'assets/styles/index.scss'

import { AppRouter } from './components/router'

function App() {
	const { tg } = useTelegram()

	useEffect(() => {
		tg.ready()
	}, [])

	return (
		<>
			<BrowserRouter>
				<AppRouter />
			</BrowserRouter>
		</>
	)
}

export default App
