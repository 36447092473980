import React, { useEffect, useState } from 'react'
import {
	useAppDispatch,
	useAppSelector,
	useTelegram,
	useDynamicAlignment,
} from 'hooks'

// @ts-ignore
import TelegramLoginButton from 'react-telegram-login'

import { useAuthTgMutation } from 'services/AuthService'
import { useGetMeQuery } from 'services/UserService'
import { useGetUserBalanceQuery } from 'services/MoneyService'
import { useGetStatsQuery } from 'services/StatsService'

import { logoutAuth } from 'store/AuthSlice'
import { logoutUser } from 'store/UserSlice'

import styles from './Header.module.scss'

import { SubHeader } from './SubHeader'
import {
	Button,
	AppLink,
	Avatar,
	Balance,
	Dropdown,
	Spinner,
	BottomMenu,
	ButtonGroup,
} from 'components/ui'

import { RushBLogo, Icon28LogoTelegramColor } from 'assets/images/svg'
import {
	Icon28LogoVk,
	Icon28DoorArrowRightOutline,
	Icon28UserCircleOutline,
	Icon28RadiowavesLeftAndRightOutline,
	Icon28LogoVkColor,
	Icon28VinylOutline,
	Icon28HomeOutline,
} from '@vkontakte/icons'

const Header: React.FC = () => {
	const { screenWidth } = useDynamicAlignment()

	const { tg, user: tgUserData } = useTelegram()
	const [authTg] = useAuthTgMutation()
	const { refetch: refetchUser } = useGetMeQuery()
	const { refetch: refetchMoney } = useGetUserBalanceQuery()
	const { refetch: refetchStats } = useGetStatsQuery()

	useEffect(() => {
		if (tgUserData) {
			authTg(tgUserData).then(() => {
				refetchUser()
				refetchMoney()
			})
		}
	}, [])

	const handleTelegramResponse = (response: any) => {
		console.log(response)
		authTg(response).then(() => {
			refetchUser()
			refetchMoney()
		})
	}

	// user

	const { user } = useAppSelector(state => state.user)
	const [isTempUser, setIsTempUser] = useState(true)

	useEffect(() => {
		setIsTempUser(user?.id === 1)
	}, [user])

	const dispatch = useAppDispatch()

	const handleLogout = () => {
		dispatch(logoutAuth())
		refetchUser()
		refetchMoney()
		refetchStats()
	}

	// balance

	const { balance } = useAppSelector(state => state.money)

	// stats

	const { stats } = useAppSelector(state => state.stats)

	// dropdown

	const [isDropdownOpen, setIsDropdownOpen] = useState(false)

	const handleAvatarClick = () => {
		if (isDropdownOpen) {
			setIsDropdownOpen(false)
		} else {
			setIsDropdownOpen(true)
		}
	}

	return (
		<header className={styles.root}>
			<SubHeader />

			<div className={styles.wrapper}>
				<div className={styles.item}>
					<AppLink to='/' flex>
						<RushBLogo />
					</AppLink>

					<ButtonGroup gap='sm' justify='left' flexNoWrap>
						{screenWidth > 368 && (
							<Button
								size='sm'
								text={stats ? `${stats?.online}` : 'N/A'}
								appearance='green'
								mode='secondary'
								before={<Icon28RadiowavesLeftAndRightOutline />}
								textColor
								noneAction
							/>
						)}

						{screenWidth > 576 && (
							<Button
								size='sm'
								text='Кейсы'
								appearance='pink'
								mode='tertiary'
								before={<Icon28VinylOutline />}
								to='/cases'
							/>
						)}
					</ButtonGroup>
				</div>

				{!user && (
					<TelegramLoginButton
						dataOnauth={handleTelegramResponse}
						botName='RushBCaseBot'
					/>
				)}

				<div className={styles.item}>
					{user && <Balance value={balance} />}

					{user && window.innerWidth > 576 && (
						<Dropdown
							isOpen={user && isDropdownOpen}
							onClose={handleAvatarClick}
							component={
								<Avatar
									onClick={handleAvatarClick}
									style={{ cursor: 'pointer' }}
									src={
										(tgUserData && tgUserData.photo_url) ||
										(user && user.avatar)
									}
									alt={
										(user && user.name) + ' ' + (user && user.lastName) ??
										undefined
									}
									name={user && user.name}
									lastName={user && user.lastName}
								/>
							}
							styleWrapper={{
								position: 'absolute',
								top: 'calc(100% + clamp(20px, 2.8dvh, 28px))',
								right: 0,
							}}
						>
							{user ? (
								<>
									{user.vk_id && (
										<Button
											text={(user && user.name) + ' ' + (user && user.lastName)}
											mode='tertiary'
											appearance='vk'
											size='md'
											before={<Icon28LogoVkColor />}
											justifyContent='start'
											to={`https://vk.com/id` + user?.vk_id}
											target='blank'
											stretched
										/>
									)}

									{user.telegram_id && (
										<Button
											text={(user && user.name) + ' ' + (user && user.lastName)}
											mode='tertiary'
											appearance='telegram'
											size='md'
											before={<Icon28LogoTelegramColor />}
											justifyContent='start'
											to={`https://t.me/` + user?.telegram_id}
											target='blank'
											stretched
										/>
									)}
								</>
							) : (
								<Spinner
									width={28}
									height={28}
									style={{ margin: '0 auto', padding: '14px 0' }}
								/>
							)}

							<Button
								text='Профиль'
								mode='tertiary'
								appearance='gray'
								size='md'
								before={<Icon28UserCircleOutline />}
								justifyContent='start'
								to='/profile'
								stretched
							/>
							<Button
								text='Выйти'
								mode='tertiary'
								appearance='red'
								size='md'
								before={<Icon28DoorArrowRightOutline />}
								justifyContent='start'
								onClick={handleLogout}
								textColor
								stretched
							/>
						</Dropdown>
					)}

					{!user && window.innerWidth > 576 && (
						<a href='https://rushb.ru/api/auth/vk/login'>
							<Button
								size='sm'
								text='Войти'
								appearance='vk'
								before={<Icon28LogoVk width={30} height={30} />}
							/>
						</a>
					)}
				</div>
			</div>

			{screenWidth < 576 && (
				<BottomMenu>
					<ButtonGroup gap='md' flexNoWrap>
						<Button
							size='md'
							appearance='gray'
							mode='tertiary'
							before={<Icon28HomeOutline width={32} height={32} />}
							to='/'
						/>

						{user && (
							<Dropdown
								isOpen={user && isDropdownOpen}
								onClose={handleAvatarClick}
								component={
									<Avatar
										onClick={handleAvatarClick}
										style={{ cursor: 'pointer', borderRadius: 16 }}
										src={
											(tgUserData && tgUserData.photo_url) ||
											(user && user.avatar)
										}
										alt={
											(user && user.name) + ' ' + (user && user.lastName) ??
											undefined
										}
										name={user && user.name}
										lastName={user && user.lastName}
										width={64}
										height={56}
									/>
								}
								styleWrapper={{
									position: 'absolute',
									bottom: 'calc(100% + 16px)',
									right: '50%',
									transform: 'translateX(50%)',
								}}
							>
								{user ? (
									<>
										{user.vk_id && (
											<Button
												text={
													(user && user.name) + ' ' + (user && user.lastName)
												}
												mode='tertiary'
												appearance='vk'
												size='md'
												before={<Icon28LogoVkColor />}
												justifyContent='start'
												to={`https://vk.com/id` + user?.vk_id}
												target='blank'
												stretched
											/>
										)}

										{user.telegram_id && (
											<Button
												text={
													(user && user.name) + ' ' + (user && user.lastName)
												}
												mode='tertiary'
												appearance='telegram'
												size='md'
												before={<Icon28LogoTelegramColor />}
												justifyContent='start'
												to={`https://t.me/` + user?.telegram_id}
												target='blank'
												stretched
											/>
										)}
									</>
								) : (
									<Spinner
										width={28}
										height={28}
										style={{ margin: '0 auto', padding: '14px 0' }}
									/>
								)}

								<Button
									text='Профиль'
									mode='tertiary'
									appearance='gray'
									size='md'
									before={<Icon28UserCircleOutline />}
									justifyContent='start'
									to='/profile'
									stretched
								/>
								<Button
									text='Выйти'
									mode='tertiary'
									appearance='red'
									size='md'
									before={<Icon28DoorArrowRightOutline />}
									justifyContent='start'
									onClick={handleLogout}
									textColor
									stretched
								/>
							</Dropdown>
						)}

						{!user && (
							<a href='https://rushb.ru/api/auth/vk/login'>
								<Button
									size='md'
									text='Войти'
									appearance='vk'
									before={<Icon28LogoVk width={32} height={32} />}
								/>
							</a>
						)}

						<Button
							size='md'
							appearance='pink'
							mode='tertiary'
							before={<Icon28VinylOutline width={32} height={32} />}
							to='/cases'
						/>
					</ButtonGroup>
				</BottomMenu>
			)}
		</header>
	)
}

export { Header }
