import { createApi } from '@reduxjs/toolkit/query/react'


import { baseQuery } from './BaseQuery'
import {UserItem} from "models/UserItem";

export const userItemApi = createApi({
	reducerPath: 'userItemApi',
	baseQuery: baseQuery('useritem'),
	tagTypes: ['UserItem'],
	endpoints: builder => ({
		getUserInventory: builder.query<UserItem[], number>({
			query: (page) => {
				return {
					url: `${page}`,
					method: 'GET'
				}
			},
		}),

		sellItem: builder.mutation<boolean, number>({
			query: itemId => ({
				url: `sell/${itemId}`,
				method: 'POST',
			}),
		}),

		tryWithdrawItem: builder.mutation<
			boolean,
			{ itemId: number }
		>({
			query: ({ itemId }) => ({
				url: `tryWithdraw/${itemId}`,
				method: 'POST'
			}),
		}),

		withdrawItem: builder.mutation<boolean, { itemId: number }>(
			{
				query: ({ itemId }) => ({
					url: `withdraw/${itemId}`,
					method: 'POST',
				}),
			}
		),
	}),
})

export const {
	useGetUserInventoryQuery,
	useSellItemMutation,
	useTryWithdrawItemMutation,
	useWithdrawItemMutation,
} = userItemApi
