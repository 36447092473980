import { useFormatNumber } from 'hooks'

import styles from './Balance.module.scss'
import BalanceProps from './Balance.interface'

import { Icon28ThunderWithArea } from 'assets/images/svg'

const Balance: React.FC<BalanceProps> = props => {
	const { value, reverse, addPlus, appearance } = props

	const balanceFormatted = useFormatNumber(value ? value / 100 : 0)

	const appearanceStyles = {
		primary: styles.primary,
		red: styles.red,
		green: styles.green,
		gray: styles.gray,
	}

	const appearanceClassName = appearanceStyles[appearance || 'primary']

	const isReverse = reverse ? styles.reverse : ''
	const plus = addPlus ? '+' : ''

	return (
		<div
			className={[styles.root, appearanceClassName, isReverse].join(' ').trim()}
		>
			<Icon28ThunderWithArea />
			{value !== 0 ? (
				<h3>
					{plus}
					{balanceFormatted}
				</h3>
			) : (
				<h3>0</h3>
			)}
		</div>
	)
}

export { Balance }
