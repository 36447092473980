import styles from './BottomMenu.module.scss'
import BottomMenuProps from './BottomMenu.interface'

const BottomMenu: React.FC<BottomMenuProps> = props => {
	const { children } = props

	return (
		<div className={[styles.root].join(' ')}>
			<div className={[styles.wrapper].join(' ')}>{children}</div>
		</div>
	)
}

export { BottomMenu }
