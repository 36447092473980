import React from 'react'

import IconProps from './IconProps.interface'

const Icon28LogoTelegramColor: React.FC<IconProps> = props => {
	let width = props.width ? props.width : 28
	let height = props.height ? props.height : 28

	return (
		<svg
			style={{ width: width, height: height }}
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26Z'
				fill='url(#paint0_linear_418_2982-086470)'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.43189 13.8733C10.9301 12.3492 13.2628 11.3444 14.43 10.8589C17.7626 9.47282 18.455 9.23203 18.9064 9.22408C19.0056 9.22234 19.2276 9.24694 19.3714 9.3636C19.4928 9.46211 19.5262 9.59518 19.5422 9.68857C19.5581 9.78197 19.578 9.99473 19.5622 10.161C19.3816 12.0585 18.6002 16.6632 18.2027 18.7884C18.0345 19.6876 17.7032 19.9891 17.3826 20.0186C16.6857 20.0828 16.1566 19.5581 15.4816 19.1157C14.4255 18.4234 13.8288 17.9924 12.8036 17.3168C11.6188 16.536 12.3869 16.1069 13.0621 15.4056C13.2388 15.2221 16.3092 12.4294 16.3686 12.176C16.376 12.1443 16.3829 12.0262 16.3128 11.9638C16.2426 11.9015 16.139 11.9228 16.0643 11.9398C15.9584 11.9638 14.2712 13.079 11.0026 15.2853C10.5237 15.6142 10.0899 15.7744 9.70129 15.766C9.27283 15.7568 8.44864 15.5238 7.83594 15.3246C7.08444 15.0803 6.48716 14.9512 6.53917 14.5363C6.56626 14.3202 6.86383 14.0992 7.43189 13.8733Z'
				fill='white'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_418_2982-086470'
					x1='14'
					y1='2'
					x2='14'
					y2='25.822'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#2AABEE' />
					<stop offset='1' stopColor='#229ED9' />
				</linearGradient>
			</defs>
		</svg>
	)
}

export { Icon28LogoTelegramColor }
