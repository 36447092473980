export const rarityMapping: Record<string, string> = {
	Ширпотреб: 'Common',
	'Промышленное качество': 'Uncommon',
	'Армейское качество': 'Rare',
	Запрещённое: 'Mythical',
	Засекреченное: 'Legendary',
	Тайное: 'Ancient',
	StatTrak: 'StatTrak',
	Сувенир: 'Immortal',
}
