import { useAppSelector } from 'hooks/redux'

import styles from './Footer.module.scss'

import { Button, ButtonGroup } from 'components/ui'

import { Icon28LogoTelegramSymbol } from 'assets/images/svg'
import {
	Icon28LogoVk,
	Icon28UsersOutline,
	Icon28VinylOutline,
} from '@vkontakte/icons'

const Footer = () => {
	const { stats } = useAppSelector(state => state.stats)

	return (
		<footer className={styles.root}>
			<div className={styles.wrapper}>
				<ButtonGroup gap='sm' justify='left'>
					<Button
						before={<Icon28LogoVk />}
						text='ВКонтакте'
						size='sm'
						appearance='vk'
						to='https://vk.com/rushbru'
						target='blank'
					/>
					<Button
						before={<Icon28LogoTelegramSymbol />}
						text='Телеграм'
						size='sm'
						appearance='telegram'
						to='https://t.me/rushbru'
						target='blank'
					/>

					<Button
						text={stats ? stats?.users : 'N/A'}
						size='sm'
						mode='secondary'
						before={<Icon28UsersOutline />}
						appearance='darkBlue'
						noneAction
					/>
					<Button
						text={stats ? stats?.cases : 'N/A'}
						size='sm'
						mode='secondary'
						before={<Icon28VinylOutline />}
						appearance='pink'
						noneAction
					/>
				</ButtonGroup>

				<div className={styles.copyright}>
					<h5>&copy; RUSH B, 2023</h5>
				</div>
			</div>
		</footer>
	)
}

export { Footer }
