import {BaseQueryFn} from "@reduxjs/toolkit/query";
import Cookies from 'js-cookie';
import axios, {AxiosRequestConfig} from 'axios'
import store from "../storeApp";

export const baseQuery = (path: string): BaseQueryFn<{
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
},
  unknown,
  unknown> =>
  async ({url, method, data, params}) => {
    try {
      const headers: { [key: string]: string } = {};
      const tokenCookie = Cookies.get('api');
      const tokenTG = store.getState().auth.token;

      // headers['Authorization'] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZvbWluaWxpeWF3YXJAbWFpbC5ydSIsInN1YiI6MSwibmFtZSI6IklseWEiLCJpYXQiOjE2OTAzNjI1MzcsImV4cCI6MTY5Mjk1NDUzN30.Crv694D66Mwi1lS7Z7PHRLKTCkKhXZAMivXqJIYpQZ8`;

      // headers[
			// 	'Authorization'
			// ] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzZHpucHJvQGdtYWlsLmNvbSIsInN1YiI6MzAsIm5hbWUiOiJBbmRyZXkiLCJpYXQiOjE2OTEyMDgyODksImV4cCI6MTY5MzgwMDI4OX0.G777_7KwWxCWnZJIh4JzNKRmSo-ElQmtbd-YwmwopCw`

      if (tokenCookie && tokenCookie !== '') {
        headers['Authorization'] = `Bearer ${tokenCookie}`;
      }
      if (tokenTG && tokenTG !== '') {
        headers['Authorization'] = `Bearer ${tokenTG}`;
      }

      const baseUrl = `https://rushb.ru/api`
      // const baseUrl = `http://localhost:3002/api`
      const result = await axios({
        url: `${baseUrl}/${path}${url ? `/${url}` : ''}`,
        method,
        data,
        params,
        headers,
        // withCredentials: true
      });

      return {data: result.data};
    } catch (axiosError: any) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  }
