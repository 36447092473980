import React from 'react'

import IconProps from './IconProps.interface'

const ArrowBottomIcon: React.FC<IconProps> = props => {
	let width = props.width ? props.width : 36
	let height = props.height ? props.height : 36

	return (
		<svg
			style={{ width: width, height: height }}
			viewBox='0 0 36 36'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M14.5358 5.5C16.0755 2.83333 19.9245 2.83333 21.4641 5.5L33.5884 26.5C35.128 29.1667 33.2035 32.5 30.1243 32.5H5.8756C2.7964 32.5 0.871894 29.1667 2.41149 26.5L14.5358 5.5Z'
				fill='#E7BB4B'
			/>
		</svg>
	)
}

export { ArrowBottomIcon }
