import React from 'react'

import IconProps from './IconProps.interface'

const Icon28LogoTelegramSymbol: React.FC<IconProps> = props => {
	let width = props.width ? props.width : 28
	let height = props.height ? props.height : 28
	let fill = props.fill ? props.fill : 'current'

	return (
		<svg
			style={{ width: width, height: height }}
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.63315 12.7489C10.4636 10.2087 14.3514 8.534 16.2967 7.72489C21.8509 5.4147 23.005 5.01339 23.7573 5.00014C23.9227 4.99723 24.2927 5.03823 24.5323 5.23267C24.7346 5.39685 24.7903 5.61863 24.8169 5.77429C24.8436 5.92995 24.8767 6.28455 24.8504 6.56163C24.5494 9.7241 23.247 17.3986 22.5845 20.9406C22.3041 22.4394 21.7521 22.9419 21.2176 22.9911C20.0562 23.098 19.1743 22.2235 18.0494 21.4861C16.2891 20.3323 15.2947 19.614 13.586 18.488C11.6114 17.1868 12.8915 16.4716 14.0168 15.3027C14.3113 14.9969 19.4286 10.3423 19.5277 9.92001C19.5401 9.86721 19.5515 9.67036 19.4346 9.56642C19.3177 9.46247 19.1451 9.49802 19.0205 9.52629C18.844 9.56636 16.0319 11.425 10.5844 15.1022C9.78621 15.6503 9.06324 15.9174 8.41548 15.9034C7.70137 15.888 6.32772 15.4996 5.30656 15.1677C4.05406 14.7606 3.05859 14.5453 3.14528 13.8539C3.19043 13.4937 3.68638 13.1254 4.63315 12.7489Z'
				fill={fill}
			/>
		</svg>
	)
}

export { Icon28LogoTelegramSymbol }
