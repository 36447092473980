import styles from './Alert.module.scss'
import AlertProps from './Alert.interface'

const Alert: React.FC<AlertProps> = props => {
	const { title, info, appearance, style } = props

	const appearanceStyles = {
		red: styles.red,
		yellow: styles.yellow,
		green: styles.green,
	}

	const appearanceClassName = appearanceStyles[appearance || 'red']

	return (
		<span
			className={[styles.root, appearanceClassName].join(' ')}
			style={style}
		>
			<h4>{title ? title : 'Alert title'}</h4>
			{info && <h5>{info}</h5>}
		</span>
	)
}

export { Alert }
