import { useEffect, useState } from 'react'

function formatNumber(number: number): string {
	if (isNaN(number)) return ''
	const absoluteNumber = Math.abs(number)
	if (absoluteNumber >= 1000000) {
		const roundedNumber = Math.round(absoluteNumber / 100000) / 10
		const suffix = number < 0 ? '-' : ''
		return `${suffix}${roundedNumber.toString().replace('.', ',')}M`
	} else if (absoluteNumber >= 1000) {
		const roundedNumber = Math.round(absoluteNumber / 100) / 10
		const suffix = number < 0 ? '-' : ''
		return `${suffix}${roundedNumber.toString().replace('.', ',')}k`
	} else {
		return number.toString()
	}
}

function useFormatNumber(initialNumber: number): string {
	const [formattedNumber, setFormattedNumber] = useState<string>(
		formatNumber(initialNumber)
	)

	useEffect(() => {
		setFormattedNumber(formatNumber(initialNumber))
	}, [initialNumber])

	return formattedNumber
}

export { useFormatNumber }
