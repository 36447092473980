import { Link } from 'react-router-dom'

import { useState, useEffect, useRef } from 'react'
import {
	useAppDispatch,
	useAppSelector,
	useCheckbox,
	useModalRoot,
} from 'hooks'

import {
	useGetCasePreviewQuery,
	useGetCaseQuery,
	useOpenCaseMutation,
} from 'services/CaseService'
import { useGetUserBalanceQuery } from 'services/MoneyService'
import {
	useSellItemMutation,
	useGetUserInventoryQuery,
} from 'services/UserItemService'

import { Item } from 'models/Item'

import styles from './Case.module.scss'
import profilePersonalStyles from '../Profile/ProfilePersonal.module.scss'

import { Layout } from 'components/layout'
import {
	Button,
	Alert,
	AppLink,
	Spinner,
	FormControl,
	Section,
	ButtonGroup,
	AsideButton,
	Empty,
} from 'components/ui'
import { WeaponCard, WeaponCardSkeleton } from 'components/shared'
import { Withdraw } from 'views/Withdraw'
import { CaseSpin } from './CaseSpin'
import { CaseFooter } from './CaseFooter'
import { ItemButtons } from './ItemButtons'

import { SteamLogo, Icon28ThunderWithArea } from 'assets/images/svg'
import {
	Icon28RefreshOutline,
	Icon28LogoVk,
	Icon28ArrowRightOutline,
	Icon28LinkOutline,
} from '@vkontakte/icons'

const Case = () => {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth)

	const { refetch: refetchMoney } = useGetUserBalanceQuery()

	let { balance } = useAppSelector(state => state.money)
	if (balance) balance = balance / 100

	const [itemsLine, setItemsLine] = useState<Item[]>([])
	const [casePrice, setCasePrice] = useState<number>(0)

	const { data: caseData } = useGetCaseQuery(1)

	useEffect(() => {
		setCasePrice(caseData ? caseData?.price / 100 : 0)
	}, [caseData])

	const { data: previewItems, refetch: refetchPreview } =
		useGetCasePreviewQuery(1)
	useEffect(() => {
		if (previewItems) setItemsLine(previewItems)
	}, [previewItems])

	const dispatch = useAppDispatch()

	const [openCase] = useOpenCaseMutation()
	const [sellItem] = useSellItemMutation()

	const openedCase = useAppSelector(state => state.case.openCase)

	const [isOpening, setIsOpening] = useState(false)
	const [isSkippingRoulette, setIsSkippingRoulette] = useState(false)
	const [isSellingItem, setIsSellingItem] = useState(false)

	const caseRef = useRef<any>(null)
	const caseWidth = caseRef.current?.clientWidth

	const caseMargin = (336 + 12) * 2
	const [spinState, setSpinState] = useState('idle')
	const [spinDataState, setSpinDataState] = useState<
		'wait' | 'open' | 'completed'
	>('wait')
	const [spinStyle, setSpinStyle] = useState<React.CSSProperties>({
		transform: `translateX(-${caseMargin}px)`,
	})
	const [lineWidth, setLineWidth] = useState<number>(0)
	const timerRef1 = useRef<any>(null)
	const timerRef2 = useRef<any>(null)

	useEffect(() => {
		return () => {
			// Очищаем таймеры и интервалы при размонтировании компонента
			clearTimeout(timerRef1.current)
			clearTimeout(timerRef2.current)
		}
	}, [])

	const [isSkipping, setIsSkipping] = useState(false)

	const spinRoulette = async () => {
		if (isOpening) {
			return
		}
		setIsOpening(true)
		try {
			// if (isAutoSale) {
			// if (openedCase?.userItem) {
			// 	dispatch(setOpenCase(null))
			// 	setSpinState('spinning')
			// 	setSpinDataState('open')
			// 	await sellItem(openedCase?.userItem.id)
			// }
			// } else if (spinState !== 'idle' || spinDataState !== 'wait') return

			setSpinState('spinning')
			setSpinDataState('open')
			setIsSkipping(false) // Reset isSkipping when spinning starts

			timerRef1.current = setTimeout(async () => {
				timerRef2.current = setTimeout(() => {
					setSpinState('completed')
				}, 5000)

				const res = await openCase(1)
				refetchMoney()
				if ('error' in res) {
					alert(res.error)
					return
				}

				setItemsLine(itemsLine.slice(-5))
				setSpinStyle({
					transition: 'none',
					transform: `translateX(-${caseMargin}px)`,
				})

				const data = res.data
				if (!data) return

				const count =
					itemsLine.length + data.items.length - (screenWidth > 1080 ? 3 : 3)
				const px = 336 * count + 12 * count
				setLineWidth(px)
				setSpinStyle({
					transition: '5s cubic-bezier(0.43, 0.16, 0, 1.01)',
					transform: `translateX(-${px}px)`,
				})

				setItemsLine([...itemsLine, ...data.items])

				setSpinDataState('completed')
			}, 100)
		} finally {
			setIsOpening(false)
		}
	}

	const skipRoulette = async () => {
		if (isSkippingRoulette) {
			return
		}
		if (spinState !== 'spinning' || spinDataState !== 'completed') return

		setIsSkippingRoulette(true)

		try {
			setSpinState('completed')
			setSpinDataState('open')
			setIsSkipping(true) // Set isSkipping to true when the user clicks "Пропустить"

			setSpinStyle({
				transition: 'none',
				transform: `translateX(-${lineWidth}px)`,
			})

			clearTimeout(timerRef1.current)
			clearTimeout(timerRef2.current)
		} finally {
			setIsSkippingRoulette(false)
		}
	}

	const caseOpen = useAppSelector(state => state.case.openCase)

	useEffect(() => {
		if (spinState === 'completed' && !caseOpen) {
			refetchMoney()
			setSpinStyle({
				transition: 'none',
				transform: `translateX(-${caseMargin}px)`,
			})
			setItemsLine(itemsLine.slice(-5))
			setSpinState('idle')
			setSpinDataState('wait')
		}
	}, [caseOpen, spinState])

	const [isAutoSale, handleAutoSale] = useCheckbox(false)

	// const handleWithdraw = () => {
	// 	dispatch(setToggleModal(true))
	// }

	const { user } = useAppSelector(state => state.user)

	const { data: inventory } = useGetUserInventoryQuery(1)

	// modal

	const {
		closeModal,
		isModalActive,
		createModalClickHandler: openModal,
	} = useModalRoot()

	return (
		<>
			<Withdraw />

			<Layout>
				<Section>
					<div className={styles.root}>
						<div className={styles.header}>
							{caseData ? (
								<h1>{caseData?.name}</h1>
							) : (
								<Spinner width={32} height={32} style={{ padding: 14 }} />
							)}
						</div>

						<CaseSpin style={spinStyle}>
							{caseData ? (
								itemsLine?.map((card, index) => (
									<WeaponCard
										key={index}
										name={card.name}
										description={card.description}
										rarity={card.rarity}
										price={card.price}
										imageUrl={card.imageUrl}
										market_hash_name={card.market_hash_name}
										exterior={card.exterior}
										size='lg'
										useRef={caseRef}
									/>
								))
							) : (
								<>
									{[...Array(5)].map((_, index) => (
										<WeaponCardSkeleton
											key={index}
											size='lg'
											rarity={'Common'}
											imageUrl={''}
											market_hash_name={''}
										/>
									))}
								</>
							)}
						</CaseSpin>

						{user ? (
							<>
								<ButtonGroup stretched>
									{balance < (casePrice || 0) ? (
										<Alert
											title={
												<>
													Цена кейса {casePrice} <Icon28ThunderWithArea /> — НЕ
													ХВАТАЕТ {casePrice - balance}{' '}
													<Icon28ThunderWithArea />
												</>
											}
											info={
												<>
													Недостаточно <u>RUSH-баллов</u> для открытия кейса,{' '}
													<AppLink>как получить баллы?</AppLink>
												</>
											}
											style={{ maxWidth: 452 }}
										/>
									) : (
										<>
											{(spinState === 'idle' ||
												(spinState === 'completed' && isAutoSale)) && (
												<ButtonGroup mode='vertical' stretched>
													<Button
														text={<>Открыть за {casePrice}</>}
														onClick={spinRoulette}
														after={<Icon28ThunderWithArea />}
														disabled={caseData ? false : true}
														maxWidth
														stretched
													/>

													{spinState === 'idle' && (
														<FormControl
															type='switch'
															checked={isAutoSale}
															onChange={handleAutoSale}
															disabled={caseData ? false : true}
														>
															Включить автопродажу
														</FormControl>
													)}
												</ButtonGroup>
											)}

											{spinState === 'completed' && (
												<>
													{/* <Button
														text='Вывести в'
														onClick={handleWithdraw}
														after={<SteamLogo />}
														mode='secondary'
														appearance='red'
														maxWidth
														textColor
														stretched
													/> */}
													<Button
														text='Открыть ещё'
														onClick={spinRoulette}
														maxWidth
														stretched
													/>
													<ItemButtons
														casePrice={casePrice}
														isSellButtonHidden={isAutoSale}
													/>
												</>
											)}

											{spinState === 'spinning' && (
												<>
													<Button
														text='Пропустить'
														onClick={skipRoulette}
														before={
															<Icon28RefreshOutline width={32} height={32} />
														}
														mode='secondary'
														maxWidth
														stretched
													/>
													<Button
														text='Открывается...'
														before={<Spinner width={32} height={32} />}
														mode='tertiary'
														noneAction
														maxWidth
														stretched
													/>
												</>
											)}
										</>
									)}
								</ButtonGroup>
							</>
						) : (
							<ButtonGroup mode='vertical' stretched>
								<Alert
									title='Чтобы открыть кейс, необходимо авторизироваться'
									style={{ maxWidth: 452 }}
								/>
								<a
									href='https://rushb.ru/api/auth/vk/login'
									style={{ width: '100%', maxWidth: 452 }}
								>
									<Button
										text='Войти через VK ID'
										before={<Icon28LogoVk width={32} height={32} />}
										appearance='vk'
										maxWidth
										stretched
									/>
								</a>
							</ButtonGroup>
						)}

						<Button
							text='Вывести в'
							onClick={openModal('attempt-withdraw-steam')}
							after={<SteamLogo />}
							mode='secondary'
							appearance='red'
							textColor
							style={{ margin: '0 auto' }}
						/>
					</div>
				</Section>

				{user && (
					<Section fill>
						<Link
							to='/profile/inventory'
							className={profilePersonalStyles.heading}
						>
							<h3>Инвентарь</h3>

							<AsideButton
								icon={<Icon28ArrowRightOutline width={24} height={24} />}
								size='sm'
								mode='secondary'
								appearance='gray'
							/>
						</Link>

						<div className={profilePersonalStyles.inventory}>
							<div className={profilePersonalStyles['inventory-list']}>
								{inventory ? (
									<>
										{inventory.map(card => (
											<WeaponCard
												key={card.id}
												name={card.item?.name}
												description={card.item?.description}
												rarity={card.item!.rarity}
												price={card.item?.price}
												imageUrl={card.item!.imageUrl}
												market_hash_name={card.item!.market_hash_name}
												exterior={card.item?.exterior}
												size='md'
												controlButtons={
													<ButtonGroup gap='sm'>
														<Button
															size='xsm'
															appearance='green'
															before={
																<Icon28ThunderWithArea width={20} height={20} />
															}
														/>
														<Button
															size='xsm'
															appearance='red'
															before={
																<Icon28LinkOutline width={20} height={20} />
															}
														/>
													</ButtonGroup>
												}
												style={{ width: 220, flexShrink: 0, padding: 32 }}
											/>
										))}

										{[...Array(20 - inventory.length)].map((_, index) => (
											<WeaponCardSkeleton
												key={index}
												size='md'
												rarity={'Common'}
												imageUrl={''}
												market_hash_name={''}
												mode='placeholder'
												style={{ width: 220, flexShrink: 0 }}
											/>
										))}
									</>
								) : (
									<Empty info='Инвентарь пуст' />
								)}
							</div>
						</div>
					</Section>
				)}

				<Section>
					<CaseFooter caseData={caseData} />
				</Section>
			</Layout>
		</>
	)
}

export { Case }
