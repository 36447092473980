import styles from './WeaponCardSkeleton.module.scss'
import WeaponCardProps from './WeaponCard.interface'

import { RushBSymbol } from 'assets/images/svg'

const WeaponCardSkeleton: React.FC<WeaponCardProps> = props => {
	const { size, style, mode } = props

	const sizeStyles = {
		sm: styles.sm,
		md: styles.md,
		lg: styles.lg,
	}

	const sizeClassName = sizeStyles[size || 'md']

	const modeStyles = {
		skeleton: styles.skeleton,
		placeholder: styles.placeholder,
	}

	const modeClassName = modeStyles[mode || 'skeleton']

	return (
		<div
			className={[styles.root, sizeClassName, modeClassName].join(' ')}
			style={style}
		>
			<div className={styles.symbol}>
				{size === 'sm' && <RushBSymbol width={29} height={36} />}
				{size === 'md' && <RushBSymbol />}
				{size === 'lg' && <RushBSymbol width={64} height={80} />}
			</div>
		</div>
	)
}

export { WeaponCardSkeleton }
