import styles from './Switch.module.scss'
import SwitchProps from './Switch.interface'

const Switch: React.FC<SwitchProps> = props => {
	const { checked, disabled, onChange, className } = props

	const isChecked = checked
		? disabled
			? styles.checkedDisabled
			: styles.checked
		: ''

	const isDisabled = checked ? '' : disabled ? styles.disabled : ''

	return (
		<>
			<input
				type='checkbox'
				className={styles.input}
				onChange={onChange}
				checked={checked}
				disabled={disabled}
			/>

			<span className={[styles.icon, isChecked, isDisabled].join(' ').trim()}>
				<span className={[styles.in, className].join(' ').trim()}></span>
			</span>
		</>
	)
}

export { Switch }
