import styles from './PayItemSkeleton.module.scss'
import PayItemProps from './PayItem.interface'

import { AsideButton, Spinner } from 'components/ui'

const PayItemSkeleton: React.FC<PayItemProps> = props => {
	const { mode } = props

	const modeStyles = {
		1: styles['mode-1'],
		2: styles['mode-2'],
	}

	const modeClassName = modeStyles[mode || '1']

	return (
		<div className={[styles.root, modeClassName].join(' ').trim()}>
			{/* <AsideButton
				icon={<Spinner width={32} height={32} />}
				size='md'
				mode='tertiary'
				appearance='gray'
				noneAction
			/> */}

			<Spinner width={32} height={32} />
		</div>
	)
}

export { PayItemSkeleton }
