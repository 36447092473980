import styles from './FormControl.module.scss'
import FormControlProps from './FormControl.interface'

import { Switch, Checkbox } from 'components/ui'

const FormControl: React.FC<FormControlProps> = props => {
	const {
		checked: initialChecked,
		disabled,
		onClick,
		onChange,
		textOverflow,
		children,
		style,
		type,
	} = props

	const isChecked = initialChecked
		? disabled
			? styles.checkedDisabled
			: styles.checked
		: ''
	const isDisabled = initialChecked ? '' : disabled ? styles.disabled : ''
	const isTextOverflow = textOverflow ? styles.textOverflow : ''

	return (
		<div
			className={[styles.root, isTextOverflow, isDisabled, isChecked]
				.join(' ')
				.trim()}
			onClick={onClick}
			style={style}
		>
			<label className={styles.wrapper}>
				{type === 'checkbox' && (
					<Checkbox
						checked={initialChecked}
						onChange={onChange}
						disabled={disabled}
					/>
				)}
				
				{type === 'switch' && (
					<Switch
						checked={initialChecked}
						onChange={onChange}
						disabled={disabled}
						className={styles.switchActive}
					/>
				)}

				{children && <h6>{children}</h6>}
			</label>
		</div>
	)
}

export { FormControl }
