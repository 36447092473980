import { useState, useEffect, ChangeEvent } from 'react'

const useCheckbox = (initialState = false) => {
	const [isCheckboxChecked, setIsCheckboxChecked] =
		useState<boolean>(initialState)

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target
		setIsCheckboxChecked(checked)
	}

	useEffect(() => {
		if (typeof initialState === 'boolean') {
			setIsCheckboxChecked(initialState)
		}
	}, [initialState])

	return [isCheckboxChecked, handleChange] as const
}

export { useCheckbox }
