import { useEffect, useState } from 'react'
import { useGoBack } from 'hooks'
import { useGetUserInventoryQuery } from 'services/UserItemService'

import { UserItem } from 'models/UserItem'

import styles from './Profile.module.scss'

import { WeaponCard, WeaponCardSkeleton } from 'components/shared'
import {
	Section,
	AsideButton,
	Button,
	ButtonGroup,
	Spinner,
} from 'components/ui'

import { Icon28ThunderWithArea } from 'assets/images/svg'
import { Icon28ArrowLeftOutline, Icon28LinkOutline } from '@vkontakte/icons'

const ProfileInventory = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const goBack = useGoBack()

	// dynamic pagination

	const [inventory, setInventory] = useState<UserItem[]>([])
	const [currentPage, setCurrentPage] = useState(1)
	const [fetching, setFetching] = useState(true)

	const { data: inventoryData } = useGetUserInventoryQuery(currentPage)

	useEffect(() => {
		if (fetching && inventoryData && inventoryData.length > 0) {
			setInventory([...inventory, ...inventoryData])
			setCurrentPage(currentPage + 1)
			setFetching(false)
		}
	}, [fetching, inventoryData])

	useEffect(() => {
		document.addEventListener('scroll', scrollHandler)

		return function () {
			document.removeEventListener('scroll', scrollHandler)
		}
	}, [])

	const scrollHandler = (event: Event) => {
		if (
			event.target instanceof Document &&
			event.target.documentElement.scrollHeight -
				(event.target.documentElement.scrollTop + window.innerHeight) <
				100
		) {
			setFetching(true)
		}
	}

	return (
		<Section countColumns={10}>
			<div className={styles['block-template']}>
				<div className={styles['heading-template']}>
					<AsideButton
						icon={<Icon28ArrowLeftOutline width={32} height={32} />}
						size='md'
						mode='secondary'
						appearance='gray'
						onClick={goBack}
					/>

					<h2>Инвентарь</h2>
				</div>

				{inventory && inventory.length > 0 ? (
					<div className={styles['inventory-list']}>
						{inventory.map((card, index) => (
							<WeaponCard
								key={`${card.id}-${index}`}
								name={card.item?.name}
								description={card.item?.description}
								rarity={card.item!.rarity}
								price={card.item?.price}
								imageUrl={card.item!.imageUrl}
								market_hash_name={card.item!.market_hash_name}
								exterior={card.item?.exterior}
								size='md'
								controlButtons={
									<ButtonGroup gap='sm'>
										<Button
											size='xsm'
											appearance='green'
											before={<Icon28ThunderWithArea width={20} height={20} />}
										/>
										<Button
											size='xsm'
											appearance='red'
											before={<Icon28LinkOutline width={20} height={20} />}
										/>
									</ButtonGroup>
								}
							/>
						))}

						{[...Array(Math.max(20 - inventory.length, 0))].map((_, index) => (
							<WeaponCardSkeleton
								key={index}
								size='md'
								rarity={'Common'}
								imageUrl={''}
								market_hash_name={''}
								mode='placeholder'
							/>
						))}
					</div>
				) : (
					<div className={styles['inventory-list']}>
						{[...Array(20)].map((_, index) => (
							<WeaponCardSkeleton
								key={index}
								size='md'
								rarity={'Common'}
								imageUrl={''}
								market_hash_name={''}
								mode='placeholder'
							/>
						))}
					</div>
				)}

				{inventoryData && inventoryData.length >= 1 && fetching && (
					<Spinner
						width={40}
						height={40}
						style={{ margin: '0 auto', padding: '24px 0' }}
					/>
				)}
			</div>
		</Section>
	)
}

export { ProfileInventory }
