import { Link } from 'react-router-dom'

import styles from './Button.module.scss'
import ButtonProps from './Button.interface'

const Button: React.FC<ButtonProps> = props => {
	const {
		text,
		mode,
		before,
		after,
		onClick,
		textColor,
		disabled,
		size,
		noneAction,
		stretched,
		maxWidth,
		appearance,
		style,
		to,
		target,
		justifyContent,
	} = props

	const appearanceStyles = {
		red: styles.red,
		yellow: styles.yellow,
		green: styles.green,
		darkBlue: styles.darkBlue,
		violet: styles.violet,
		pink: styles.pink,
		gray: styles.gray,
		vk: styles.vk,
		telegram: styles.telegram,
	}

	const appearanceClassName = appearanceStyles[appearance || 'violet']

	const modeStyles = {
		primary: styles.primary,
		secondary: styles.secondary,
		tertiary: styles.tertiary,
		outline: styles.outline,
	}

	const modeClassName = modeStyles[mode || 'primary']

	const sizeStyles = {
		xsm: styles['size-xsm'],
		sm: styles['size-sm'],
		md: styles['size-md'],
		lg: styles['size-lg'],
	}

	const sizeClassName = sizeStyles[size || 'lg']

	const targetKeywords = {
		self: 'self',
		blank: 'blank',
		parent: 'parent',
		top: 'top',
	}

	const targetValue = targetKeywords[target || 'self']

	const justifyContentStyles = {
		start: styles['content-flex-start'],
		center: styles['content-center'],
	}

	const justifyContentClassName =
		justifyContentStyles[justifyContent || 'center']

	const isTextColor = textColor ? styles.textColor : ''
	const isButtonStretched = stretched ? styles.stretched : ''
	const isLinkDisabled = disabled ? styles['disabled-link'] : ''
	const isNoneAction = noneAction ? styles['none-action'] : ''
	const isMaxWidth = maxWidth ? styles['max-width'] : ''

	const buttonContent = (
		<span
			className={[styles.in, appearanceClassName, justifyContentClassName]
				.join(' ')
				.trim()}
		>
			{before}
			{text && <h4 className={isTextColor}>{text}</h4>}
			{after}
		</span>
	)

	if (to) {
		return (
			<Link
				to={to}
				target={`_${targetValue}`}
				className={[
					styles.root,
					sizeClassName,
					mode ? modeClassName : modeStyles['primary'],
					isNoneAction,
					isButtonStretched,
					isLinkDisabled,
					isMaxWidth,
				]
					.join(' ')
					.trim()}
				style={style}
			>
				{buttonContent}
			</Link>
		)
	} else {
		return (
			<button
				onClick={onClick}
				className={[
					styles.root,
					sizeClassName,
					mode ? modeClassName : modeStyles['primary'],
					isNoneAction,
					isButtonStretched,
					isMaxWidth,
				]
					.join(' ')
					.trim()}
				style={style}
				disabled={disabled}
			>
				{buttonContent}
			</button>
		)
	}
}

export { Button }
