import {combineReducers, configureStore} from '@reduxjs/toolkit'

import userSlice from './UserSlice'
import moneySlice from './MoneySlice'
import caseSlice from './CaseSlice'
import itemSlice from './ItemSlice'
import modalSlice from "store/ModalSlice";
import authSlice from "store/AuthSlice";
import statsSlice from 'store/StatsSlice'

import {userApi} from 'services/UserService'
import {moneyApi} from 'services/MoneyService'
import {caseApi} from 'services/CaseService'
import {itemApi} from 'services/ItemService'
import {userItemApi} from 'services/UserItemService'
import {authApi} from "services/AuthService";
import { statsApi } from 'services/StatsService'

const rootReducer = combineReducers({
	user: userSlice,
	money: moneySlice,
	case: caseSlice,
	item: itemSlice,
	modal: modalSlice,
	auth: authSlice,
	stats: statsSlice,

	[userApi.reducerPath]: userApi.reducer,
	[moneyApi.reducerPath]: moneyApi.reducer,
	[caseApi.reducerPath]: caseApi.reducer,
	[itemApi.reducerPath]: itemApi.reducer,
	[userItemApi.reducerPath]: userItemApi.reducer,
	[authApi.reducerPath]: authApi.reducer,
	[statsApi.reducerPath]: statsApi.reducer,
})

export const setupStore = () => {
  return configureStore({
		reducer: rootReducer,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware().concat([
				userApi.middleware,
				moneyApi.middleware,
				caseApi.middleware,
				itemApi.middleware,
				userItemApi.middleware,
				authApi.middleware,
				statsApi.middleware,
			]),
	})
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
