import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQuery } from './BaseQuery'
import {setAuth} from "store/AuthSlice";

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: baseQuery('auth'),
	tagTypes: ['auth'],
	endpoints: builder => ({
		authTg: builder.mutation<string, any>({
			query: (tgData) => ({
				url: 'telegram/connect',
				method: 'POST',
				data: tgData,
			}),
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled
					dispatch(setAuth(data))
				} catch (error) {
					dispatch(setAuth(''))
				}
			},
		}),
	}),
})

export const { useAuthTgMutation } = authApi
