import { createApi } from '@reduxjs/toolkit/query/react'

import { User } from 'models/User'
import { setUser } from 'store/UserSlice'

import { baseQuery } from './BaseQuery'

export const userApi = createApi({
	reducerPath: 'userApi',
	baseQuery: baseQuery('user'),
	tagTypes: ['User'],
	endpoints: builder => ({
		getMe: builder.query<User, void>({
			query: () => {
				return {
					url: '',
					method: 'GET'
				}
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled
					dispatch(setUser(data))
				} catch (error) {
					dispatch(setUser(null))
				}
			},
		}),
	}),
})

export const { useGetMeQuery } = userApi
