import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {User} from "models/User";
import Cookies from "js-cookie";
import store from "../storeApp";

export interface UserState {
  user: User | null
}

const initialState: UserState = {
  user: null,
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, {payload}: PayloadAction<User | null>) => {
      state.user = payload
    },
    logoutUser: (state) => {
      state.user = null;
    },
  },
})

// Action creators are generated for each case reducer function
export const {setUser, logoutUser} = UserSlice.actions

export default UserSlice.reducer
