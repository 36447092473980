import { useEffect, useState } from 'react'
import {
	useAppDispatch,
	useAppSelector,
	useDynamicAlignment,
	useModalRoot,
} from 'hooks'
import { useTryWithdrawItemMutation } from 'services/UserItemService'

import { setOpenCase } from 'store/CaseSlice'

import { Alert, Button, ButtonGroup, ModalRoot } from 'components/ui'

import {
	Icon28BlockOutline,
	Icon28DoorArrowRightOutline,
} from '@vkontakte/icons'

const Withdraw = () => {
	const { screenWidth } = useDynamicAlignment()
	const { closeModal, isModalActive } = useModalRoot()

	const dispatch = useAppDispatch()

	const { openCase } = useAppSelector(state => state.case)
	const [tryWithdrawItem] = useTryWithdrawItemMutation()
	const [tryWithdrawStatus, setTryWithdrawStatus] = useState('wait')

	useEffect(() => {
		setTryWithdrawStatus('wait')
	}, [openCase])

	const handleTryWithdraw = async () => {
		if (openCase && openCase.userItem) {
			const tryWithdrawRes = await tryWithdrawItem({
				itemId: openCase.userItem.id,
			})
			setTryWithdrawStatus(tryWithdrawRes ? 'success' : 'error')
			dispatch(setOpenCase(null))
		}
	}

	return (
		<ModalRoot
			isActive={isModalActive('attempt-withdraw-steam')}
			header={
				<>
					{tryWithdrawStatus === 'wait' && (
						<p>Кейс сломан: вывод в данный момент нестабилен</p>
					)}
					{tryWithdrawStatus === 'success' && (
						<p>НЕВЕРОЯТНО, ТЫ ВЫИГРАЛ, ВВОДИ СКИН В СВОЕМ ИНВЕНТАРЕ</p>
					)}
					{tryWithdrawStatus === 'error' && <p>НЕУДАЧА, КЕЙС СЛОМАН </p>}
				</>
			}
			text={
				<>
					Шанс удачного вывода <u>составляет 1%</u>
				</>
			}
		>
			{tryWithdrawStatus === 'wait' && (
				<Alert
					title={
						<>В случае неудачи ты потеряешь скин M4A1-S | Скоростной зверь</>
					}
				/>
			)}

			<ButtonGroup gap='md' justify='right' stretched>
				{tryWithdrawStatus === 'wait' && (
					<Button
						size='md'
						mode='secondary'
						appearance='red'
						text='Пробую'
						before={<Icon28BlockOutline />}
						style={{ maxWidth: screenWidth > 526 ? 220 : '100%' }}
						onClick={handleTryWithdraw}
						stretched
						textColor
					/>
				)}

				<Button
					size='md'
					text='Ливаю'
					before={<Icon28DoorArrowRightOutline />}
					style={{ maxWidth: screenWidth > 526 ? 220 : '100%' }}
					onClick={closeModal}
					stretched
				/>
			</ButtonGroup>
		</ModalRoot>
	)
}

export { Withdraw }
