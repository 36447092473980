import { createApi } from '@reduxjs/toolkit/query/react'

import { Case } from 'models/Case'
import { OpenCase } from 'models/OpenCase'
import { Item } from 'models/Item'

import { baseQuery } from './BaseQuery'
import {setUser} from "store/UserSlice";
import {setOpenCase} from "store/CaseSlice";

export const caseApi = createApi({
	reducerPath: 'caseApi',
	baseQuery: baseQuery('case'),
	tagTypes: ['Case'],
	endpoints: builder => ({
		getCasePreview: builder.query<Item[], number>({
			query: caseId => ({
				url: `preview/${caseId}`,
				method: 'GET'
			}),
		}),

		openCase: builder.mutation<OpenCase, number>({
			query: caseId => ({ url: `open/${caseId}`, method: 'POST' }),
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled
					dispatch(setOpenCase(data))
				} catch (error) {
					dispatch(setOpenCase(null))
				}
			},
		}),

		createCase: builder.mutation<Case, Partial<Case>>({
			query: newCase => ({
				url: '/',
				method: 'POST',
				data: newCase,
			}),
		}),

		getCase: builder.query<Case, number>({
			query: id => ({
				url: `${id}`,
				method: 'GET'
			}),
		}),

		getCasesList: builder.query<Case[], { page: number; limit: number }>({
			query: ({ page = 1, limit = 10 }) => ({
				url: `?page=${page}&limit=${limit}`,
				method: 'GET'
			}),
		}),
	}),
})

export const {
	useGetCasePreviewQuery,
	useOpenCaseMutation,
	useCreateCaseMutation,
	useGetCaseQuery,
	useGetCasesListQuery,
} = caseApi
