import styles from './Section.module.scss'
import SectionProps from './Section.interface'

const Section: React.FC<SectionProps> = props => {
	const { children, style, fill, countColumns, withoutPadding } = props

	const widthColumnsStyles = {
		12: styles[`width-12-columns`],
		10: styles[`width-10-columns`],
		8: styles[`width-8-columns`],
		6: styles[`width-6-columns`],
		4: styles[`width-4-columns`],
	}

	const widthClassName = widthColumnsStyles[countColumns || '12']

	const fillStyle = fill ? styles.fill : ''
	const withoutPaddingStyle = withoutPadding ? '' : styles.padding

	return (
		<section className={styles.root} style={style}>
			<div
				className={[styles.inner, widthClassName, withoutPaddingStyle]
					.join(' ')
					.trim()}
			>
				{fill ? <div className={fillStyle}>{children}</div> : <>{children}</>}
			</div>
		</section>
	)
}

export { Section }
