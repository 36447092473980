import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {User} from "models/User";
import Cookies from "js-cookie";
import store from "../storeApp";

export interface AuthState {
  token: string
}

const initialState: AuthState = {
  token: '',
}

export const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setAuth: (state, {payload}: PayloadAction<string>) => {
      state.token = payload
    },
    logoutAuth: (state) => {
      Cookies.set('api', '')
      state.token = '';
    }
  },
})

// Action creators are generated for each case reducer function
export const {setAuth, logoutAuth} = AuthSlice.actions

export default AuthSlice.reducer
