import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Item } from 'models/Item' // adjust this import to match your project structure

interface ItemState {
	items: Item[] | null
	item: Item | null
}

const initialState: ItemState = {
	items: null,
	item: null,
}

const ItemSlice = createSlice({
	name: 'item',
	initialState,
	reducers: {
		setItems: (state: ItemState, action: PayloadAction<Item[] | null>) => {
			state.items = action['payload']
		},
		setItem: (state: ItemState, action: PayloadAction<Item | null>) => {
			state.item = action['payload']
		},
	},
})

export const { setItems, setItem } = ItemSlice.actions

export default ItemSlice.reducer
