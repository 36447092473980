import { Link } from 'react-router-dom'

import { useFormatDate } from 'hooks'

import { User } from 'models/User'
import { UserItem } from 'models/UserItem'
import { Money } from 'models/Money'

import styles from './ProfilePersonal.module.scss'

import { PayItem, WeaponCard, WeaponCardSkeleton } from 'components/shared'
import {
	AsideButton,
	Avatar,
	Button,
	ButtonGroup,
	Empty,
	Section,
} from 'components/ui'
import { ProviderTile } from './ProviderTile'

import { Icon28LogoTelegramColor, Icon28ThunderWithArea } from 'assets/images/svg'
import {
	Icon28LinkOutline,
	Icon28ArrowRightOutline,
	Icon28CopyOutline,
	Icon28LogoVkColor,
	Icon28CheckCircleOutline,
	Icon28CancelCircleOutline,
} from '@vkontakte/icons'

interface ProfilePersonalProps {
	user: User | null
	tgUserData: any
	inventory: UserItem[] | undefined
	transactions: Money[] | undefined
}

const ProfilePersonal: React.FC<ProfilePersonalProps> = props => {
	const { user, tgUserData, inventory, transactions } = props

	const { formatDate } = useFormatDate()

	const handleCopyClick = () => {
		if (user?.id) {
			navigator.clipboard
				.writeText(user?.id.toString())
				.then(() => {
					console.log('User ID copied to clipboard:', user?.id)
				})
				.catch(error => {
					console.error('Error copying to clipboard:', error)
				})
		}
	}

	return (
		<>
			<Section fill countColumns={6}>
				<div className={styles.UserInfo}>
					<Avatar
						src={(tgUserData && tgUserData.photo_url) || (user && user.avatar)}
						alt={(user && user.name) + ' ' + (user && user.lastName)}
						name={user && user.name}
						lastName={user && user.lastName}
						primary
					/>

					<div className={styles['display-name']}>
						<h2>
							{user
								? (user && user.name) + ' ' + (user && user.lastName)
								: 'N/A'}
						</h2>

						<div className={styles['additional-info']}>
							<ButtonGroup gap='sm'>
								<Button
									text={formatDate(user?.createdAt, false, true)}
									size='xsm'
									mode='secondary'
									appearance='gray'
									noneAction
									textColor
								/>
								<Button
									text={`RUSH ID: ${user ? user?.id : 'N/A'}`}
									size='xsm'
									mode='secondary'
									// after={<Icon28CopyOutline width={20} height={20} />}
									appearance='gray'
									onClick={handleCopyClick}
									noneAction
									textColor
								/>
							</ButtonGroup>
						</div>
					</div>
				</div>
			</Section>

			{/* <Section fill countColumns={6}>
				<ProviderTile
					icon={<Icon28LogoVkColor width={44} height={44} />}
					title='ВКонтакте'
				>
					<Button
						text={user?.vk_id ? 'Привязан' : 'Не привязан'}
						size='xsm'
						mode='secondary'
						before={
							user?.vk_id ? (
								<Icon28CheckCircleOutline width={20} height={20} />
							) : (
								<Icon28CancelCircleOutline width={20} height={20} />
							)
						}
						appearance={user?.vk_id ? 'green' : 'red'}
						noneAction
						textColor
					/>
				</ProviderTile>
			</Section>

			<Section fill countColumns={6}>
				<ProviderTile
					icon={<Icon28LogoTelegramColor width={44} height={44} />}
					title='Телеграм'
				>
					<Button
						text={user?.telegram_id ? 'Привязан' : 'Не привязан'}
						size='xsm'
						mode='secondary'
						before={
							user?.telegram_id ? (
								<Icon28CheckCircleOutline width={20} height={20} />
							) : (
								<Icon28CancelCircleOutline width={20} height={20} />
							)
						}
						appearance={user?.telegram_id ? 'green' : 'red'}
						noneAction
						textColor
					/>
				</ProviderTile>
			</Section> */}

			<Section fill countColumns={6}>
				<Link to='inventory' className={styles.heading}>
					<h3>Инвентарь</h3>

					<AsideButton
						icon={<Icon28ArrowRightOutline width={24} height={24} />}
						size='sm'
						mode='secondary'
						appearance='gray'
					/>
				</Link>

				<div className={styles.inventory}>
					<div className={styles['inventory-list']}>
						{inventory ? (
							<>
								{inventory.map(card => (
									<WeaponCard
										key={card.id}
										name={card.item?.name}
										description={card.item?.description}
										rarity={card.item!.rarity}
										price={card.item?.price}
										imageUrl={card.item!.imageUrl}
										market_hash_name={card.item!.market_hash_name}
										exterior={card.item?.exterior}
										size='md'
										controlButtons={
											<ButtonGroup gap='sm'>
												<Button
													size='xsm'
													appearance='green'
													before={
														<Icon28ThunderWithArea width={20} height={20} />
													}
												/>
												<Button
													size='xsm'
													appearance='red'
													before={<Icon28LinkOutline width={20} height={20} />}
												/>
											</ButtonGroup>
										}
										style={{ width: 220, flexShrink: 0, padding: 32 }}
									/>
								))}

								{[...Array(20 - inventory.length)].map((_, index) => (
									<WeaponCardSkeleton
										key={index}
										size='md'
										rarity={'Common'}
										imageUrl={''}
										market_hash_name={''}
										mode='placeholder'
										style={{ width: 220, flexShrink: 0 }}
									/>
								))}
							</>
						) : (
							<Empty info='Инвентарь пуст'>
								<Button
									text='Крутить кейсы'
									size='xsm'
									mode='secondary'
									after={<Icon28LinkOutline width={20} height={20} />}
									appearance='gray'
									to='/cases'
								/>
							</Empty>
						)}
					</div>
				</div>
			</Section>

			<Section fill countColumns={6}>
				<Link to='pay-history' className={styles.heading}>
					<h3>История пополнений</h3>

					<AsideButton
						icon={<Icon28ArrowRightOutline width={24} height={24} />}
						size='sm'
						mode='secondary'
						appearance='gray'
					/>
				</Link>

				{transactions ? (
					transactions.map(item => (
						<PayItem
							key={item.id}
							amount={item.amount}
							description={
								item.description
							}
							additionalInfo={
							formatDate(item.createdAt, true, true)
							}
							mode='2'
						/>
					))
				) : (
					<Empty info='История пополнений пуста'>
						<Button
							text='Крутить кейсы'
							size='xsm'
							mode='secondary'
							after={<Icon28LinkOutline width={20} height={20} />}
							appearance='gray'
							to='/cases'
						/>
					</Empty>
				)}
			</Section>
		</>
	)
}

export { ProfilePersonal }
