import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	activeModalId: null,
	scrollPosition: 0,
}

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		setToggleModal: (state, action) => {
			state.activeModalId = action.payload
			
			if (action.payload) {
				state.scrollPosition = window.scrollY
				document.body.style.position = 'fixed'
				document.body.style.top = `-${state.scrollPosition}px`
				document.body.classList.add('scrollbar-stable')
			} else {
				document.body.style.position = ''
				document.body.style.top = ''
				document.body.classList.remove('scrollbar-stable')
				window.scrollTo(0, state.scrollPosition)
				state.scrollPosition = 0
			}
		},
	},
})

export const { setToggleModal } = modalSlice.actions

export default modalSlice.reducer
