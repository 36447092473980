import styles from './ButtonGroup.module.scss'
import ButtonGroupProps from './ButtonGroup.interface'

const ButtonGroup: React.FC<ButtonGroupProps> = props => {
	const { children, mode, stretched, gap, align, justify, style, flexNoWrap } =
		props

	const modeStyles = {
		horizontal: styles.horizontal,
		vertical: styles.vertical,
	}

	const modeClassName = modeStyles[mode || 'horizontal']

	const gapStyles = {
		sm: styles['gap-sm'],
		md: styles['gap-md'],
		lg: styles['gap-lg'],
	}

	const gapClassName = gapStyles[gap || 'lg']

	const alignStyles = {
		start: styles['align-start'],
		center: styles['align-center'],
		end: styles['align-end'],
	}

	const alignClassName = alignStyles[align || 'center']

	const justifyStyles = {
		left: styles['justify-left'],
		center: styles['justify-center'],
		right: styles['justify-right'],
	}

	const justifyClassName = justifyStyles[justify || 'center']

	const isButtonGroupStretched = stretched ? styles.stretched : ''
	const isButtonGroupNoWrap = flexNoWrap ? '' : styles['flex-wrap']

	return (
		<div
			className={[
				styles.root,
				modeClassName,
				isButtonGroupStretched,
				gapClassName,
				alignClassName,
				justifyClassName,
				isButtonGroupNoWrap,
			]
				.join(' ')
				.trim()}
			style={style}
		>
			{children}
		</div>
	)
}

export { ButtonGroup }
