import { createApi } from '@reduxjs/toolkit/query/react'

import { Item } from 'models/Item'

import { baseQuery } from './BaseQuery'

export const itemApi = createApi({
	reducerPath: 'itemApi',
	baseQuery: baseQuery('item'),
	tagTypes: ['Item'],
	endpoints: builder => ({
		getAllItems: builder.query<Item[], void>({
			query: () => {
				return {
					url: '',
					method: 'GET'
				}
			},
		}),

		getItemById: builder.query<Item, number>({
			query: id => {
				return {
					url: `${id}`,
					method: 'GET'
				}
			},
		}),

		createItem: builder.mutation<Item, Partial<Item>>({
			query: newItem => ({
				url: '/',
				method: 'POST',
				data: newItem,
			}),
		}),

		updateItem: builder.mutation<Item, { id: number; data: Partial<Item> }>({
			query: ({ id, data }) => ({
				url: `/${id}`,
				method: 'PUT',
				data: data,
			}),
		}),

		deleteItem: builder.mutation<{ success: boolean }, number>({
			query: id => ({
				url: `/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
})

export const {
	useGetAllItemsQuery,
	useGetItemByIdQuery,
	useCreateItemMutation,
	useUpdateItemMutation,
	useDeleteItemMutation,
} = itemApi
