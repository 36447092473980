import { useAppDispatch, useAppSelector } from 'hooks'
import { RootState } from 'store'
import { setToggleModal } from 'store/ModalSlice'

const useModalRoot = () => {
	const dispatch = useAppDispatch()
	const { activeModalId } = useAppSelector((state: RootState) => state.modal)

	const openModal = (modalId: string) => {
		dispatch(setToggleModal(modalId))
	}

	const closeModal = () => {
		dispatch(setToggleModal(null))
	}

	const isModalActive = (modalId: string) => {
		return activeModalId === modalId
	}

	const createModalClickHandler = (modalId: string) => {
		return () => openModal(modalId)
	}

	return { openModal, closeModal, isModalActive, createModalClickHandler }
}

export { useModalRoot }
