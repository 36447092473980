import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector, useModalRoot } from 'hooks'
import { useGetCasePreviewQuery, useGetCaseQuery } from 'services/CaseService'
import { useGetUserBalanceQuery } from 'services/MoneyService'

import { Item } from 'models/Item'

import styles from './Cases.module.scss'

import { Layout } from 'components/layout'
import { Button, Spinner, Section, ButtonGroup, ModalRoot } from 'components/ui'

import {
	Icon28BookmarkAddOutline,
	Icon28SettingsOutline,
	Icon28MoneyRequestOutline,
	Icon28DoorArrowRightOutline,
} from '@vkontakte/icons'

const Cases = () => {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth)
	const handleResize = () => {
		setScreenWidth(window.innerWidth)
	}

	useEffect(() => {
		// Add event listener to update screenWidth on resize
		window.addEventListener('resize', handleResize)

		// Clean up the event listener when component unmounts
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const { refetch: refetchMoney } = useGetUserBalanceQuery()

	let { balance } = useAppSelector(state => state.money)
	if (balance) balance = balance / 100

	const [itemsLine, setItemsLine] = useState<Item[]>([])
	const [casePrice, setCasePrice] = useState<number>(0)

	const { data: caseData } = useGetCaseQuery(1)

	useEffect(() => {
		setCasePrice(caseData ? caseData?.price / 100 : 0)
	}, [caseData])

	const { data: previewItems, refetch: refetchPreview } =
		useGetCasePreviewQuery(1)

	useEffect(() => {
		if (previewItems) setItemsLine(previewItems)
	}, [previewItems])

	const dispatch = useAppDispatch()

	const { user } = useAppSelector(state => state.user)

	// modal

	const {
		closeModal,
		isModalActive,
		createModalClickHandler: openModal,
	} = useModalRoot()

	return (
		<Layout>
			<Section>
				<ModalRoot
					isActive={isModalActive('popup1')}
					header='Первый попап'
					countColumns={4}
				>
					<ButtonGroup gap='md' justify='right' stretched>
						<Button
							onClick={closeModal}
							size='md'
							text='Закрыть'
							before={<Icon28DoorArrowRightOutline />}
							appearance='green'
							mode='secondary'
							textColor
						/>
					</ButtonGroup>
				</ModalRoot>

				<ModalRoot
					isActive={isModalActive('popup2')}
					header='Второй попап'
					countColumns={4}
				>
					<ButtonGroup gap='md' justify='right' stretched>
						<Button
							onClick={closeModal}
							size='md'
							text='Закрыть'
							before={<Icon28DoorArrowRightOutline />}
							appearance='yellow'
							mode='secondary'
							textColor
						/>
					</ButtonGroup>
				</ModalRoot>

				<ModalRoot
					isActive={isModalActive('popup3')}
					header='Третий попап'
					countColumns={4}
				>
					<ButtonGroup gap='md' justify='right' stretched>
						<Button
							onClick={closeModal}
							size='md'
							text='Закрыть'
							before={<Icon28DoorArrowRightOutline />}
							appearance='red'
							mode='secondary'
							textColor
						/>
					</ButtonGroup>
				</ModalRoot>

				<div className={styles.root}>
					<div className={styles.header}>
						{caseData ? (
							<h1>Кейсы</h1>
						) : (
							<Spinner width={32} height={32} style={{ padding: 14 }} />
						)}
					</div>

					<ButtonGroup
						mode='horizontal'
						gap='md'
						style={{ margin: '0 auto', paddingTop: 44 }}
					>
						<Button
							onClick={openModal('popup1')}
							text='Первый попап'
							size='md'
							appearance='green'
							mode='secondary'
							before={<Icon28BookmarkAddOutline />}
							textColor
							maxWidth
						/>
						<Button
							onClick={openModal('popup2')}
							text='Второй попап'
							size='md'
							appearance='yellow'
							mode='secondary'
							before={<Icon28SettingsOutline />}
							textColor
							maxWidth
						/>
						<Button
							onClick={openModal('popup3')}
							text='Третий попап'
							size='md'
							appearance='red'
							mode='secondary'
							before={<Icon28MoneyRequestOutline />}
							textColor
							maxWidth
						/>
					</ButtonGroup>
				</div>
			</Section>
		</Layout>
	)
}

export { Cases }
