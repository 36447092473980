import { Link } from 'react-router-dom'

import styles from './AsideButton.module.scss'
import AsideButtonProps from './AsideButton.interface'

const AsideButton: React.FC<AsideButtonProps> = props => {
	const {
		icon,
		mode,
		onClick,
		disabled,
		size,
		textColor,
		noneAction,
		appearance,
		style,
		to,
		target,
		text,
	} = props

	const appearanceStyles = {
		red: styles.red,
		yellow: styles.yellow,
		green: styles.green,
		darkBlue: styles.darkBlue,
		violet: styles.violet,
		pink: styles.pink,
		gray: styles.gray,
	}

	const appearanceClassName = appearanceStyles[appearance || 'violet']

	const modeStyles = {
		primary: styles.primary,
		secondary: styles.secondary,
		tertiary: styles.tertiary,
		outline: styles.outline,
	}

	const modeClassName = modeStyles[mode || 'primary']

	const sizeStyles = {
		xsm: styles['size-xsm'],
		sm: styles['size-sm'],
		md: styles['size-md'],
		lg: styles['size-lg'],
	}

	const sizeClassName = sizeStyles[size || 'lg']

	const targetKeywords = {
		self: 'self',
		blank: 'blank',
		parent: 'parent',
		top: 'top',
	}

	const targetValue = targetKeywords[target || 'self']

	const isTextColor = textColor ? styles.textColor : ''
	const isLinkDisabled = disabled ? styles['disabled-link'] : ''
	const isNoneAction = noneAction ? styles['none-action'] : ''

	const buttonContent = (
		<span className={[styles.in, appearanceClassName].join(' ').trim()}>
			{text ? <h3 className={isTextColor}>{text}</h3> : icon}
		</span>
	)

	if (to) {
		return (
			<Link
				to={to}
				target={`_${targetValue}`}
				className={[
					styles.root,
					sizeClassName,
					mode ? modeClassName : modeStyles['primary'],
					isNoneAction,
					isLinkDisabled,
				]
					.join(' ')
					.trim()}
				style={style}
			>
				{buttonContent}
			</Link>
		)
	} else {
		return (
			<button
				onClick={onClick}
				className={[
					styles.root,
					sizeClassName,
					mode ? modeClassName : modeStyles['primary'],
					isNoneAction,
				]
					.join(' ')
					.trim()}
				style={style}
				disabled={disabled}
			>
				{buttonContent}
			</button>
		)
	}
}

export { AsideButton }
