import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { Stats } from 'models/Stats'

export interface StatsState {
	stats: Stats | null
}

const initialState: StatsState = {
	stats: null,
}

export const StatsSlice = createSlice({
	name: 'stats',
	initialState,
	reducers: {
		setStats: (state, { payload }: PayloadAction<Stats | null>) => {
			state.stats = payload
		},
	},
})

export const { setStats } = StatsSlice.actions

export default StatsSlice.reducer
