import styles from './Empty.module.scss'
import EmptyProps from './Empty.interface'

import { Icon28InfoCircleOutline } from '@vkontakte/icons'

const Empty: React.FC<EmptyProps> = props => {
	const { info, icon, children } = props

	return (
		<section className={[styles.root].join(' ')}>
			<div className={styles.info}>
				{icon ? icon : <Icon28InfoCircleOutline width={24} height={24} />}
				<h5>{info ? info : 'Empty block header'}</h5>
			</div>

			{children}
		</section>
	)
}

export { Empty }
