import React from 'react'

import IconProps from './IconProps.interface'

const RushBLogo: React.FC<IconProps> = props => {
	let width = props.width ? props.width : 124
	let height = props.height ? props.height : 30

	return (
		<svg
			style={{ width: width, height: height }}
			viewBox='0 0 124 30'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_194_2474-328499)'>
				<path
					d='M120.789 14.4745C123.34 12.4014 124.328 9.63409 123.894 6.59195C123.561 4.27297 122.39 2.60967 120.302 1.57313C118.914 0.883702 117.246 0.686035 115.708 0.686035C114.401 0.686035 113.099 0.686035 111.793 0.686035H104.691C104.691 0.686035 102.348 19.7585 101.177 29.2899C101.177 29.2899 109.199 29.2851 113.056 29.2899C113.982 29.2899 114.898 29.208 115.799 28.9958C118.041 28.4655 119.96 27.3856 121.45 25.6017C122.626 24.1891 123.296 22.5596 123.484 20.7276C123.638 19.2282 123.426 17.8011 122.66 16.4801C122.202 15.675 121.561 15.0193 120.789 14.4745ZM117.945 12.3532C114.372 16.8658 110.805 21.3832 107.232 25.8958C107.102 26.0598 106.933 26.1658 106.711 26.0405C106.538 25.9392 106.538 25.7705 106.639 25.5101C107.859 22.4294 109.074 19.3439 110.293 16.2632C110.322 16.1909 110.347 16.1137 110.385 16.0028H107.237C107.15 16.0028 107.058 16.0028 106.972 16.0028C106.629 15.998 106.475 15.7714 106.62 15.458C106.948 14.7542 107.28 14.0503 107.613 13.3464C108.948 10.5019 110.284 7.66224 111.614 4.81776C111.735 4.56224 111.889 4.46582 112.169 4.46582C113.856 4.47546 115.544 4.47064 117.231 4.47064C117.41 4.47064 117.588 4.46582 117.694 4.64902C117.81 4.84187 117.708 5.00097 117.612 5.16006C116.378 7.23316 115.144 9.30625 113.909 11.3842C113.856 11.4709 113.803 11.5626 113.726 11.6975H114.011C115.216 11.6975 116.421 11.6975 117.626 11.6975C117.805 11.6975 117.974 11.6975 118.06 11.8807C118.152 12.0591 118.056 12.2134 117.945 12.3532Z'
					fill='url(#paint0_linear_194_2474-552866)'
				/>
				<path
					d='M59.2471 7.37776C60.0089 6.89564 60.8719 6.80886 61.7493 6.85225C62.4821 6.89082 63.1908 7.0451 63.8369 7.42597C64.6613 7.91291 65.1627 8.66501 65.5387 9.51835C65.611 9.68227 65.6785 9.85101 65.7557 10.0342C67.6745 8.98802 69.5692 7.95147 71.5266 6.886C71.1939 6.14836 70.8468 5.48787 70.437 4.84183C68.7544 2.18538 66.3776 0.883667 63.1812 0.695642C62.4146 0.618504 61.6288 0.700463 60.8574 0.772781C60.2547 0.830635 59.6521 0.946342 59.0687 1.11026C56.8076 1.74183 54.9033 2.93748 53.51 4.85147C52.0781 6.8185 51.5429 9.03623 51.8756 11.442C52.0443 12.6617 52.5939 13.7176 53.4569 14.6095C54.5899 15.781 56.0073 16.4849 57.5212 17.0056C58.7457 17.425 59.9992 17.777 61.2334 18.1771C62.0048 18.4278 62.7617 18.7171 63.4222 19.1992C64.1695 19.7392 64.4106 20.5684 64.0827 21.4362C63.8128 22.1449 63.2728 22.5644 62.5882 22.8054C61.2383 23.2779 59.8594 23.2779 58.4806 22.9742C57.2367 22.7042 56.2628 22.0196 55.6072 20.9252C55.3275 20.4575 54.8502 19.3776 54.8502 19.3776L49.0504 22.5114C49.0504 22.5114 49.6 24.8351 51.3887 26.6479C52.9845 28.263 55.0479 28.9572 57.2656 29.2224C57.2656 29.2224 60.6983 29.719 63.7356 28.6776C65.3073 28.1376 66.7488 27.4 67.93 26.2188C69.9935 24.1554 70.8131 21.6339 70.5431 18.7509C70.4129 17.3238 69.7765 16.133 68.7255 15.1639C67.5733 14.1033 66.1751 13.491 64.724 12.9848C63.5621 12.575 62.3809 12.2182 61.2142 11.8084C60.3849 11.5143 59.5653 11.1768 58.8663 10.6176C58.5095 10.3331 58.2491 9.986 58.1913 9.51835C58.0756 8.56376 58.4709 7.86469 59.2471 7.37776Z'
					fill='white'
				/>
				<path
					d='M6.36392 29.2947L7.59331 19.2571H10.5583L15.0854 29.2947H21.9266L16.8258 18.1916C16.8258 18.1916 18.735 17.2949 20.0946 15.8148C21.7579 14.0068 22.2303 12.9317 22.6449 11.36C22.939 10.2512 23.0065 9.12301 22.8715 7.97558C22.6353 5.94105 21.7868 4.21508 20.2681 2.84588C18.6627 1.40435 16.7342 0.753496 14.584 0.753496H3.5339L0 29.2947H6.36392ZM14.637 13.2355C14.1404 13.5537 13.586 13.7176 12.9978 13.7224C11.7877 13.7272 8.38398 13.7272 8.2731 13.7272L9.13608 6.68833H13.7451C15.1818 6.68833 16.281 7.61882 16.5124 9.03623C16.7824 10.6899 16.0496 12.3387 14.637 13.2355Z'
					fill='white'
				/>
				<path
					d='M40.1795 17.9939C40.0734 18.8184 39.9625 19.6524 39.7456 20.4527C39.4659 21.4844 38.8006 22.2462 37.8123 22.6849C36.4383 23.2972 35.0064 23.3261 33.5697 22.9886C32.1137 22.6415 31.3809 21.7159 31.3664 20.2117C31.3568 19.5367 31.4677 18.8521 31.5496 18.1771C31.7569 16.4078 33.6902 0.705285 33.6902 0.705285H27.3504C27.3504 0.705285 25.2773 17.6372 25.0652 19.2764C24.9109 20.4479 24.8289 21.6194 25.0411 22.7958C25.3255 24.3771 26.0149 25.7415 27.1816 26.8504C28.7003 28.2919 30.5661 28.9717 32.6103 29.2465C32.6103 29.2465 34.7653 29.5599 37.4893 29.044C39.2972 28.7017 40.946 27.9737 42.3972 26.8359C44.6246 25.0907 45.8925 22.791 46.2589 19.9899C46.4518 18.5098 48.631 0.714927 48.631 0.714927H42.3056C42.3056 0.700463 40.4784 15.6991 40.1795 17.9939Z'
					fill='white'
				/>
				<path
					d='M89.4999 11.7505H80.923L82.2778 0.681179H75.9283L72.4137 29.2947H78.7776L80.1806 17.8445H88.7526L87.3496 29.2947H93.7039L97.2233 0.681179H90.8546L89.4999 11.7505Z'
					fill='white'
				/>
			</g>
			<defs>
				<linearGradient
					id='paint0_linear_194_2474-552866'
					x1='112.585'
					y1='0.686035'
					x2='112.585'
					y2='29.2899'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#9D48DC' />
					<stop offset='1' stopColor='#804BE8' />
				</linearGradient>
				<clipPath id='clip0_194_2474-328499'>
					<rect
						width='124'
						height='28.6858'
						fill='white'
						transform='translate(0 0.656982)'
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export { RushBLogo }
