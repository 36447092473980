import styles from './CaseSpin.module.scss'
import SpinProps from './CaseSpin.interface'

import { ArrowBottomIcon } from 'assets/images/svg'

const CaseSpin: React.FC<SpinProps> = props => {
	const { style, children } = props

	return (
		<div className={styles.root}>
			<div className={styles.arrow}>
				<ArrowBottomIcon />
			</div>
			<div className={styles.container}>
				<div className={`${styles.wrapper}`}>
					<div className={styles.list} style={style}>
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

export { CaseSpin }
