import { useFormatNumber } from 'hooks'

import styles from './WeaponCard.module.scss'

import WeaponCardProps from './WeaponCard.interface'
import { rarityMapping } from './rarityMapping'

import { Icon28Thunder } from 'assets/images/svg'

const WeaponCard: React.FC<WeaponCardProps> = props => {
	const {
		name,
		rarity,
		imageUrl,
		market_hash_name,
		size,
		price,
		useRef,
		style,
		controlButtons,
	} = props

	const isBalanceFormatted = useFormatNumber(price ? price / 100 : 0)

	const englishRarity = rarityMapping[rarity]

	const rarityStyles: { [key: string]: string } = {
		Common: styles.common,
		Uncommon: styles.unCommon,
		Rare: styles.rare,
		Mythical: styles.mythical,
		Legendary: styles.legendary,
		Ancient: styles.ancient,
		StatTrak: styles.statTrak,
		Immortal: styles.immortal,
	}

	const sizeStyles: { [key: string]: string } = {
		sm: styles.sm,
		md: styles.md,
		lg: styles.lg,
	}

	const rarityClassName = rarityStyles[englishRarity || 'Mythical']
	const sizeClassName = sizeStyles[size || 'md']

	return (
		<div
			ref={useRef}
			className={[styles.root, rarityClassName, sizeClassName].join(' ')}
			style={style}
		>
			{size === 'sm' && (
				<div className={styles.thunder}>
					<Icon28Thunder width={18} height={18} />
				</div>
			)}

			{size !== 'sm' && (
				<div className={styles.header}>
					<div className={styles.price}>
						{size === 'md' && <Icon28Thunder width={18} height={18} />}
						{size === 'lg' && <Icon28Thunder width={22} height={22} />}

						<h4>{isBalanceFormatted ? isBalanceFormatted : '0'}</h4>
					</div>

					{controlButtons}
				</div>
			)}

			<div className={styles.image}>
				<img src={imageUrl} alt={`${market_hash_name}`} />
			</div>

			{size !== 'sm' && (
				<div className={styles.footer}>
					<h4>{(name || market_hash_name)?.split('|')[0]}</h4>
					<h3>{(name || market_hash_name)?.split('|')[1]}</h3>
				</div>
			)}
		</div>
	)
}

export { WeaponCard }
