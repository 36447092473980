import styles from './Checkbox.module.scss'
import CheckboxProps from './Checkbox.interface'

import { Icon28DoneOutline } from '@vkontakte/icons'

const Checkbox: React.FC<CheckboxProps> = props => {
	const { checked, disabled, onChange } = props

	const isChecked = checked
		? disabled
			? styles.checkedDisabled
			: styles.checked
		: ''

	const isDisabled = checked ? '' : disabled ? styles.disabled : ''

	return (
		<>
			<input
				type='checkbox'
				className={styles.input}
				onChange={onChange}
				checked={checked}
				disabled={disabled}
			/>

			<span className={[styles.icon, isChecked, isDisabled].join(' ').trim()}>
				<span className={styles.in}>
					<Icon28DoneOutline width={20} height={20} />
				</span>
			</span>
		</>
	)
}

export { Checkbox }
