export const RarityOrder = [
	'Ширпотреб',
	'Промышленное качество',
	'Армейское качество',
	'Запрещённое',
	'Засекреченное',
	'Тайное',
	'StatTrak',
	'Сувенир',
] as const

export type RarityTypes = (typeof RarityOrder)[number]

export interface Item {
	id: number
	name: string
	description: string
	rarity: RarityTypes
	price: number
	imageUrl: string
	market_hash_name: string
	exterior: string
}
