import { createApi } from '@reduxjs/toolkit/query/react'

import { Money } from 'models/Money'
import { setBalance } from 'store/MoneySlice'

import { baseQuery } from './BaseQuery'

export const moneyApi = createApi({
	reducerPath: 'moneyApi',
	baseQuery: baseQuery('money'),
	tagTypes: ['Money'],
	endpoints: builder => ({
		getTransactions: builder.query<Money[], { page: number; limit: number }>({
			query: ({ page = 1, limit = 10 }) => {
				return {
					url: `transactions?page=${page}&limit=${limit}`,
					method: 'GET'
				}
			},
		}),

		getUserBalance: builder.query<number, void>({
			query: () => {
				return {
					url: '',
					method: 'GET'
				}
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled
					dispatch(setBalance(data))
				} catch (error) {
					dispatch(setBalance(0))
				}
			},
		}),

		getDrops: builder.query<Money[], void>({
			query: () => {
				return {
					url: 'drops',
					method: 'GET'
				}
			},
		})
	}),
})

export const { useGetTransactionsQuery, useGetUserBalanceQuery, useGetDropsQuery } = moneyApi
