import { useEffect, useState } from 'react'
import { useGetDropsQuery } from 'services/MoneyService'

import { Item } from 'models/Item'

import styles from './SubHeader.module.scss'

import { WeaponCard, WeaponCardSkeleton } from 'components/shared'

interface SubHeaderProps {
	scrollStyle?: boolean
}

const SubHeader: React.FC<SubHeaderProps> = props => {
	const { scrollStyle } = props

	const carouselStyles = {
		containerClass: styles.liveContainer,
		sliderClass: styles.liveSlider,
		itemClass: styles.liveItem,
	}

	const [items, setItems] = useState<(Item | undefined)[]>([])

	const { data, isLoading, refetch } = useGetDropsQuery()

	useEffect(() => {
		refetch()

		const interval = setInterval(() => {
			refetch()
		}, 1000)

		return () => clearInterval(interval)
	}, [refetch])

	useEffect(() => {
		if (data) {
			setItems(data.map(it => it.item))
		}
	}, [data])

	return (
		<div className={`${styles.root}`}>
			<div className={styles.wrapper}>
				<div className={styles.live}>
					{data
						? items?.map((card, index) => (
								<WeaponCard
									key={index}
									rarity={card?.rarity || ''}
									imageUrl={card?.imageUrl || ''}
									market_hash_name={card?.market_hash_name || ''}
									size='sm'
								/>
						  ))
						: [...Array(20)].map((_, index) => (
								<WeaponCardSkeleton
									key={index}
									size='sm'
									rarity={'Common'}
									imageUrl={''}
									market_hash_name={''}
								/>
						  ))}
				</div>
			</div>
		</div>
	)
}

export { SubHeader }
