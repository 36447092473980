import ReactDOM from 'react-dom'

interface PortalProps {
	children: React.ReactNode
}

const Portal: React.FC<PortalProps> = ({ children }) => {
	const portal = document.getElementById('portal')

	return ReactDOM.createPortal(children, portal!)
}

export { Portal }
