import { useEffect, useState } from 'react'

import { RarityOrder, RarityTypes } from 'models/Item'

const useSortedRarity = <T extends { rarity: RarityTypes }>(data: T[]): T[] => {
	const [sortedData, setSortedData] = useState<T[]>([])

	useEffect(() => {
		if (data) {
			const dataCopy = [...data]

			const sortedData = dataCopy.sort((a, b) => {
				const colorIndexA = RarityOrder.indexOf(a.rarity)
				const colorIndexB = RarityOrder.indexOf(b.rarity)
				return colorIndexA - colorIndexB
			})

			setSortedData(sortedData)
		}
	}, [data])

	return sortedData
}

export { useSortedRarity }
