import React from 'react'

import IconProps from './IconProps.interface'

const RushBSymbol: React.FC<IconProps> = props => {
	let width = props.width ? props.width : 52
	let height = props.height ? props.height : 64

	return (
		<svg
			style={{ width: width, height: height }}
			viewBox='0 0 52 64'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_160_2491-710554)'>
				<path
					d='M44.3592 30.8796C50.0589 26.2466 52.2677 20.0621 51.298 13.2634C50.5545 8.08088 47.9364 4.3637 43.271 2.04719C40.168 0.506449 36.44 0.0646973 33.003 0.0646973C30.0831 0.0646973 27.174 0.0646973 24.2541 0.0646973H8.38337C8.38337 0.0646973 3.147 42.6884 0.528809 63.9895C0.528809 63.9895 18.4575 63.9787 27.077 63.9895C29.1457 63.9895 31.1929 63.8063 33.2077 63.3322C38.2178 62.147 42.506 59.7336 45.8353 55.747C48.4643 52.5901 49.9619 48.9483 50.3822 44.8541C50.7269 41.5032 50.2528 38.314 48.5397 35.3618C47.5161 33.5624 46.0831 32.0971 44.3592 30.8796ZM38.0023 26.1389C30.0184 36.2237 22.0454 46.3194 14.0615 56.4043C13.7706 56.7706 13.3935 57.0076 12.8978 56.7275C12.51 56.5012 12.51 56.1241 12.7362 55.5423C15.4622 48.6574 18.1773 41.7618 20.9033 34.8769C20.9679 34.7153 21.0218 34.5429 21.108 34.2951H14.0723C13.8783 34.2951 13.6736 34.2951 13.4797 34.2951C12.7147 34.2843 12.3699 33.7779 12.6931 33.0776C13.4258 31.5045 14.1693 29.9315 14.9127 28.3584C17.8972 22.0015 20.8817 15.6553 23.8555 9.2984C24.1248 8.72735 24.4696 8.51186 25.0945 8.51186C28.8656 8.53341 32.6366 8.52264 36.4077 8.52264C36.8064 8.52264 37.205 8.51186 37.442 8.92129C37.7006 9.35227 37.4744 9.70783 37.2589 10.0634C34.5006 14.6964 31.7424 19.3294 28.9841 23.9732C28.8656 24.1671 28.7471 24.3718 28.5747 24.6735H29.2104C31.904 24.6735 34.5976 24.6735 37.2912 24.6735C37.6899 24.6735 38.067 24.6735 38.2609 25.083C38.4656 25.4816 38.2501 25.8264 38.0023 26.1389Z'
					fill='current'
				/>
			</g>
			<defs>
				<clipPath id='clip0_160_2491-710554'>
					<rect
						width='51.0709'
						height='64.0002'
						fill='current'
						transform='translate(0.464355)'
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export { RushBSymbol }
