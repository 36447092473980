import { useEffect, useState } from 'react'
import { useSortedRarity } from 'hooks/useSortedRarity'

import { Case } from 'models/Case'
import { Item, RarityOrder } from 'models/Item'

import styles from './Case.module.scss'

import { WeaponCard, WeaponCardSkeleton } from 'components/shared/WeaponCard'

const CaseFooter = ({ caseData }: { caseData: Case | undefined }) => {
	const [sortedItems, setItems] = useState<Item[] | undefined>([])

	useEffect(() => {
		if (caseData && caseData.items.length > 0) {
			const items = [...caseData.items]
			const sortedData = items.sort((a, b) => {
				const colorIndexA = RarityOrder.indexOf(a.rarity)
				const colorIndexB = RarityOrder.indexOf(b.rarity)
				return colorIndexA - colorIndexB
			})
			setItems(sortedData)
		}
	}, [caseData?.items])

	return (
		<section className={styles.footer}>
			<h2>Содержимое кейсa</h2>

			<div className={styles.footerList}>
				{caseData
					? sortedItems?.map(card => (
							<WeaponCard
								key={card.id}
								name={card.name}
								description={card.description}
								rarity={card.rarity}
								price={card.price}
								imageUrl={card.imageUrl}
								market_hash_name={card.market_hash_name}
								exterior={card.exterior}
								size='md'
							/>
					  ))
					: [...Array(12)].map((_, index) => (
							<WeaponCardSkeleton
								key={index}
								size='md'
								rarity={'Common'}
								imageUrl={''}
								market_hash_name={''}
							/>
					  ))}
			</div>
		</section>
	)
}

export { CaseFooter }
