import React, { useRef, useEffect } from 'react'
import { useMount, useDynamicAlignment, useAppDispatch } from 'hooks'
import { setToggleModal } from 'store/ModalSlice'

import styles from './ModalRoot.module.scss'
import ModalRootProps from './ModalRoot.interface'

import { AsideButton, Portal, Section } from 'components/ui'

import { Icon28CancelOutline } from '@vkontakte/icons'

const ModalRoot: React.FC<ModalRootProps> = props => {
	const { children, isActive, header, text, countColumns } = props

	const { screenHeight } = useDynamicAlignment()

	const containerRef = useRef<HTMLDivElement | null>(null)
	const modalRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		updateModalAlignment()
	}, [screenHeight])

	function updateModalAlignment() {
		const container = containerRef.current
		const modal = modalRef.current

		if (container !== null && modal !== null) {
			if (modal.offsetHeight > container.offsetHeight) {
				container.classList.remove(styles['center-align'])
			} else {
				container.classList.add(styles['center-align'])
			}
		}
	}

	const dispatch = useAppDispatch()
	const onHidden = () => {
		dispatch(setToggleModal(false))
	}

	const ANIMATION_TIME = 240
	const { mounted } = useMount({ isActive, ANIMATION_TIME })

	if (!mounted) {
		return null
	}

	return (
		<Portal>
			<div
				role='dialog'
				className={[styles.root, isActive ? styles.active : ''].join(' ')}
			>
				<div
					ref={containerRef}
					className={[styles.container, styles['center-align']].join(' ')}
				>
					<Section countColumns={countColumns || 6}>
						<div ref={modalRef} className={styles.wrapper}>
							<AsideButton
								icon={<Icon28CancelOutline width={24} height={24} />}
								size='sm'
								mode='secondary'
								appearance='gray'
								onClick={onHidden}
								style={{ position: 'absolute', top: 12, right: 12 }}
							/>

							<div className={styles.content}>
								{header && <h3>{header}</h3>}
								{text && <h5>{text}</h5>}
							</div>

							{children}
						</div>
					</Section>
				</div>

				<div
					role='button'
					tabIndex={0}
					onClick={onHidden}
					className={styles.overlay}
				/>
			</div>
		</Portal>
	)
}

export { ModalRoot }
