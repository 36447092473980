import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Case, Cases, Join, Profile } from 'views'

export const AppRouter: React.FC = () => {
	return (
		<Routes>
			<Route path='/' element={<Case />} />
			<Route path='/profile/*' element={<Profile />} />
			<Route path='/cases/*' element={<Cases />} />
			{/* <Route path='/join' element={<Join />} /> */}

			{/* <Route path='/404' element={<NotFound />} /> */}
			<Route path='*' element={<Navigate to='/' replace />} />
		</Routes>
	)
}
