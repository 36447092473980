import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Case } from 'models/Case'
import { Item } from 'models/Item'
import {OpenCase} from "models/OpenCase";

interface CaseState {
	casePreview: Item[]
	openCase: OpenCase | null
	cases: Case[]
}

const initialState: CaseState = {
	casePreview: [],
	openCase: null,
	cases: [],
}

const caseSlice = createSlice({
	name: 'case',
	initialState,
	reducers: {
		setCasePreview: (
			state: CaseState,
			{ payload }: PayloadAction<Item[]>
		) => {
			state.casePreview = payload
		},
		setOpenCase: (
			state: CaseState,
			{ payload }: PayloadAction<OpenCase | null>
		) => {
			state.openCase = payload
		},
		setCases: (state: CaseState, { payload }: PayloadAction<Case[]>) => {
			state.cases = payload
		},
	},
})

export const { setCasePreview, setOpenCase, setCases } = caseSlice.actions

export default caseSlice.reducer
