import React from 'react'

import IconProps from './IconProps.interface'

const Icon28Thunder: React.FC<IconProps> = props => {
	let width = props.width ? props.width : 28
	let height = props.height ? props.height : 28
	let fill = props.fill ? props.fill : 'current'

	return (
		<svg
			style={{ width: width, height: height }}
			viewBox='0 0 28 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.8414 20.4607C17.3427 17.2973 19.8441 14.1339 22.3471 10.9722C22.5024 10.7763 22.6376 10.5601 22.5092 10.3101C22.3876 10.0534 22.1512 10.0534 21.9012 10.0534H16.8344H16.4358C16.5439 9.86427 16.6182 9.73591 16.6925 9.6143C18.2017 7.07334 19.711 4.53751 21.2203 2.00234C25.2831 4.45279 28 8.90874 28 13.9993C28 21.7312 21.732 27.9993 14 27.9993C12.4152 27.9993 10.8919 27.7359 9.47143 27.2506C11.2618 24.9877 13.0516 22.7242 14.8414 20.4607ZM7.66104 26.4853C3.11459 24.1726 0 19.4496 0 13.9993C0 6.32117 6.18095 0.0867124 13.8386 0.000183105C13.6862 0.0754153 13.5737 0.207477 13.4768 0.412968C12.0637 3.43361 10.6468 6.45036 9.22894 9.46912C8.77585 10.4338 8.32267 11.3986 7.86949 12.3639L7.86811 12.3668C7.40243 13.3522 6.93674 14.3375 6.4778 15.3229C6.27513 15.762 6.49131 16.0796 6.97097 16.0863H7.34254H11.7541C11.7369 16.1358 11.7217 16.1804 11.7077 16.2216C11.6778 16.3098 11.6533 16.382 11.6257 16.4511C10.7711 18.6096 9.91812 20.7699 9.06518 22.9301C8.59723 24.1152 8.12928 25.3004 7.66104 26.4853Z'
				fill={fill}
			/>
		</svg>
	)
}

export { Icon28Thunder }
