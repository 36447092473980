import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { useTelegram } from 'hooks'
import { useAuthTgMutation } from 'services/AuthService'
import { useGetMeQuery } from 'services/UserService'
import {
	useGetUserBalanceQuery,
	useGetTransactionsQuery,
} from 'services/MoneyService'
import { useGetUserInventoryQuery } from 'services/UserItemService'

import styles from './Profile.module.scss'

import { Layout } from 'components/layout'
import { Spinner, Section } from 'components/ui'
import { ProfilePersonal } from './ProfilePersonal'
import { ProfilePayHistory } from './ProfilePayHistory'
import { ProfileInventory } from './ProfileInventory'

const Profile = () => {
	// telegram, user

	const { user } = useAppSelector(state => state.user)

	const { tg, user: tgUserData } = useTelegram()
	const [authTg] = useAuthTgMutation()
	const { refetch: refetchUser } = useGetMeQuery()
	const { refetch: refetchMoney } = useGetUserBalanceQuery()
	const { data: transactions } = useGetTransactionsQuery({
		page: 1,
		limit: 3,
	})

	useEffect(() => {
		if (tgUserData) {
			authTg(tgUserData).then(() => {
				refetchUser()
				refetchMoney()
			})
		} else {
			refetchUser()
			refetchMoney()
		}
	}, [])

	// inventory

	const { data: inventory } = useGetUserInventoryQuery(1)

	return (
		<Layout style={{ gap: 12 }}>
			{user ? (
				<Routes>
					<Route
						path='/*'
						element={
							<ProfilePersonal
								user={user}
								tgUserData={tgUserData}
								inventory={inventory}
								transactions={transactions}
							/>
						}
					/>
					<Route path='inventory' element={<ProfileInventory />} />
					<Route path='pay-history' element={<ProfilePayHistory />} />
				</Routes>
			) : (
				<Section countColumns={6}>
					<Spinner
						width={40}
						height={40}
						style={{ margin: '0 auto', padding: '40px 0' }}
					/>
				</Section>
			)}
		</Layout>
	)
}

export { Profile }
