import styles from './PayItem.module.scss'
import PayItemProps from './PayItem.interface'

import { AsideButton, Balance } from 'components/ui'
import {
	Icon28MoneyRequestOutline,
	Icon28VinylOutline,
	Icon28FireOutline,
	Icon28GiftOutline,
	Icon16LogoVk,
} from '@vkontakte/icons'

const PayItem: React.FC<PayItemProps> = props => {
	const { amount, description, additionalInfo, mode } = props

	const modeStyles = {
		1: styles['mode-1'],
		2: styles['mode-2'],
	}

	const modeClassName = modeStyles[mode || '1']

	return (
		<div className={[styles.root, modeClassName].join(' ').trim()}>
			{description?.includes('Первый баланс') && (
				<AsideButton
					icon={<Icon28GiftOutline width={32} height={32} />}
					size='md'
					mode='secondary'
					appearance='yellow'
					noneAction
				/>
			)}

			{description?.includes('Критическая ошибка') && (
				<AsideButton
					icon={<Icon28FireOutline width={32} height={32} />}
					size='md'
					mode='secondary'
					appearance='red'
					noneAction
				/>
			)}

			{description === 'Коментарий в группе' && (
				<AsideButton
					icon={<Icon16LogoVk width={32} height={32} />}
					size='md'
					mode='secondary'
					appearance='darkBlue'
					noneAction
				/>
			)}

			{description === 'Продажа предмета' && (
				<AsideButton
					icon={<Icon28MoneyRequestOutline width={32} height={32} />}
					size='md'
					mode='secondary'
					appearance='violet'
					noneAction
				/>
			)}

			{description === 'Открытие кейса' && (
				<AsideButton
					icon={<Icon28VinylOutline width={32} height={32} />}
					size='md'
					mode='secondary'
					appearance='pink'
					noneAction
				/>
			)}

			<div className={styles.info}>
				<div className={styles.description}>
					<h4>{description}</h4>

					{additionalInfo && <h6>{additionalInfo}</h6>}
				</div>

				<div className={styles['balance-wrapper']}>
					<Balance
						value={amount}
						addPlus={amount! > 0}
						appearance={amount! > 0 ? 'green' : 'red'}
						reverse
					/>
				</div>
			</div>
		</div>
	)
}

export { PayItem }
