import { createApi } from '@reduxjs/toolkit/query/react'

import { setStats } from 'store/StatsSlice'
import { Stats } from 'models/Stats'

import { baseQuery } from './BaseQuery'

export const statsApi = createApi({
	reducerPath: 'statsApi',
	baseQuery: baseQuery('stats'),
	tagTypes: ['Stats'],
	endpoints: builder => ({
		getStats: builder.query<Stats, void>({
			query: () => {
				return {
					url: '',
					method: 'GET',
				}
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled
					dispatch(setStats(data))
				} catch (error) {
					dispatch(setStats(null))
				}
			},
		}),
	}),
})

export const { useGetStatsQuery } = statsApi
