import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Money} from "models/Money";

interface MoneyState {
  transactions: Money[];
  balance: number;
}

const initialState: MoneyState = {
  transactions: [],
  balance: 0,
}

const MoneySlice = createSlice({
  name: 'money',
  initialState,
  reducers: {
    setTransactions: (state: MoneyState, action: PayloadAction<Money[]>) => {
      state.transactions = action["payload"];
    },
    setBalance: (state: MoneyState, action: PayloadAction<number>) => {
      state.balance = action["payload"];
    },
  },
})

export const {setTransactions, setBalance} = MoneySlice.actions;

export default MoneySlice.reducer;
